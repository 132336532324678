import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../knowledge.css";
import Footer from '../Components/Footer';
import ScrollToTop from "react-scroll-to-top";
import { useHistory } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
import jan from "../images/1.png";
import febbg from "../assets/feb/febbg.png";
import marchbg from "../assets/march/marchbg.png";
import bg from "../assets/april/1.png";
import mayBg from "../assets/may/maybg.png";
import junebg from "../assets/june/junebg.png";
import iotbg from "../images/iotbg.png";
import ioticon from "../images/ioticon.png";

import { BsArrowRightShort } from "react-icons/bs";
import data from "../images/data.png";
import bluetooth from "../images/bluetooth.png";
import exim from "../images/exim.png";
import egm from "../images/egm.png";
import blockchainbg from "../images/blockchainbg.png";
import decbg from "../images/decbg.png";
import novbg from "../images/novbg.png";
import octbg from "../images/octbg.png";
import gnss from "../images/gnss.png";
import rfid from "../images/rfid.png";

import qrcode from "../images/barcode.png";
import cpu from "../images/cpu.png";

import logistics from "../images/logistics.png";
import blockchain from "../images/blockchainn.png";
import products from "../images/products.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, EffectCoverflow } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, EffectCoverflow]);

// import SwiperCore, {
//     EffectCoverflow,
//     Pagination,
//     Navigation,
// } from "swiper/core";
// SwiperCore.use([EffectCoverflow, Pagination, Navigation]);



const KnowledgeBank = () => {
    const history = useHistory()
    var menu = ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'March', 'April', 'May', 'June']
    const [blogs, setBlogs] = useState([
        {
            img: octbg,
            innerImg: gnss,
            heading: 'Global navigation satellite system (GNSS)',
            month: 'October'
        },
        {
            img: novbg,
            innerImg: rfid,
            heading: 'RFID',
            month: 'November'
        },
        {
            img: decbg,
            innerImg: logistics,
            heading: 'Logistics',
            month: 'December'
        },
        {
            img: blockchainbg,
            innerImg: blockchain,
            heading: 'Blockchain',
            month: 'January'
        },
        {
            img: febbg,
            innerImg: data,
            heading: 'Open Source Technologies',
            month: 'February'
        },
        {
            img: jan,
            innerImg: products,
            heading: "Lynkit's Products",
            month: 'March'
        },
        {
            img: bg,
            innerImg: bluetooth,
            heading: 'Bluetooth and Bluetooth Low Energy (BLE)',
            month: 'April'
        },
        {
            img: mayBg,
            innerImg: exim,
            heading: 'Export and Import Logistics',
            month: 'May'
        },
        {
            img: junebg,
            innerImg: cpu,
            heading: "IC's and Semiconductors",
            month: 'july'
        },
        {
            img: bg,
            innerImg: qrcode,
            heading: "Barcodes, QR codes, 1D and 2D codes",
            month: 'sept'
        },
        {
            img: iotbg,
            innerImg: ioticon,
            heading: "Internet of Things (IoT)",
            month: 'june'
        },

    ])
    const _moveTo = (item) => {
        history.push({
            pathname: '/knowledge-bank/posts',
            state: { detail: item }
        })
    }

    return (
        <>

            <ScrollToTop id="scrolling_to_top" smooth color="black" />
            <div className="main_company" >
                <Helmet>
                    <title>Lynkit - Orchestrating Efficiency In Your Supply Chain </title>
                    <meta
                        name="description"
                        content="Automate and digitally transform your supply chain with Lynkit's blockchain, telematics and IoT based logistics optimisation solutions."
                    />
                    <meta
                        name="keywords"
                        content="Transport Management System, Rfid, Radio Frequency Identification, Bluetooth, Ble, Bluetooth low energy, Warehouse Management system, Container tracking, Container location, Gnss, Gps, Irnss, Ais 140, Global Navigation Satellite System, Real time tracking, Port automation system, Port automation, Warehouse automation, Yard automation system, Yard automation, Gate automation, Parcel tracking, Export tracking, Export documentation, Lorry receipt automation, Fleet management system, Digital invoicing system, Digital lorry receipt, Digital consignment note, E-consignment note, Digital goods receipt, Loading bay automation, Route management, Indent management, Route optimisation"
                    />
                </Helmet>
                <div className="knowledgeHeading">
                    <span className="knowText">Did You <span className="knowSpecialText">Know?</span></span>
                </div>
                <div className="carouselElem">
                    <Swiper
                         navigation={true}
                         loop={true} // This will make the slides loop infinitely
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                    >
                        {
                            blogs.map((item, index) => {
                                return (
                                    <SwiperSlide>
                                        <div className="knowItems" onClick={() => _moveTo(item.month)}>
                                            <img className="knowSize" src={item.img} alt='' />
                                            <div className="knowInnerItems">
                                                <img className="knowInnerImg" src={item.innerImg} alt='' />
                                                <span>{item.heading}</span>
                                            </div>
                                            <div className="knowMore">
                                                <div className="knowmore2">
                                                    <span>Know More</span>
                                                    <BsArrowRightShort className="knowRightArrow" onClick={() => _moveTo()} />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Swiper>
                </div>
                <div className="knowledge_footer">
                    <Footer />
                </div>
            </div >
        </>
    )
}

export default KnowledgeBank;