import React , {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';   

ReactDOM.render(
  <BrowserRouter>
    <App />
</BrowserRouter>,
  document.getElementById('root')
);
