import React from "react";
import mainStyles from "../../main.module.css";
import styles from "./styles.module.css";
import { StyledHeading } from "../../HelperComponents";
import logistics3pls from "../../assets/industries/logistics_3PLs.svg";
import warehouse_dist from "../../assets/industries/warehouse_dist.svg";
import automobiles from "../../assets/industries/automobiles.svg";
import export_import from "../../assets/industries/export_import.svg";
import consumer_elec from "../../assets/industries/consumer_elec.svg";
import public_sec_govt from "../../assets/industries/public_sec_govt.svg";
import ecom from "../../assets/industries/ecom.svg";
import container_terminals from "../../assets/industries/container_terminals.svg";
import mining_metals from "../../assets/industries/mining_metals.svg";
import marketplace from "../../assets/industries/marketplace.svg";
import railways from "../../assets/industries/railways.svg";
import shipping_lines from "../../assets/industries/shipping_lines.svg";
import public_transport from "../../assets/industries/public_transport.svg";
import d2c from "../../assets/industries/d2c.svg";
import security from "../../assets/industries/security.svg";
import oil_gas_energy from "../../assets/industries/oil_gas_energy.svg";
import air_cargo from "../../assets/industries/air_cargo.svg";
import pharma from "../../assets/industries/pharma.svg";
import jewellery from "../../assets/industries/jewellery.svg";
import it from "../../assets/industries/it.svg";
import { FadeInComponent, SlideLeftComponent, SlideRightComponent } from "../../AnimationConents";

const Industries = ({ current, setCurrent }) => {
  const industries = [
    {
      name: "Logistics and 3PLs",
      img: logistics3pls,
      content: [
        "Camera for Driver Monitoring",
        "Camera for Back Door Monitoring",
        "GPS Locking",
        "Cold Chain Tracking and Management",
        "Fuel Utilisation Tracking",
      ],
    },
    {
      name: "Warehousing and Distribution",
      img: warehouse_dist,
      content: [
        "Forklfit Tracking and Managment",
        "MHE Operator Shift Management",
        "RFID Based Forklift Location Automation",
        "Real-Time Cold Chain Management and Monitoring",
      ],
    },
    {
      name: "Public sector and Government",
      img: public_sec_govt,
      content: [
        "Tracking Of public utility vehicles such as municipal authority vehicles and trucks",
        "Bus and Passenger attendance tracking and route optimisation",
        "Dashcameras for security and public utility vehicles such as police vans",
        "GPS Locks for high security movement of weapons and ammunition",
      ],
    },
    {
      name: "Marketplace",
      img: marketplace,
      content: [
        "Real-time tracking and tracing of mid mile shipments and transporter monitoring",
        "Camera for back door monitoring for theft prevention",
        "GPD locking for reducing thefts and in transit losses",
        "GPS locking for theft reduction in transit",
        "Last-mile real time tracking visibility",
        "Real time ETA updates for your customers",
        "Product shipment tracking via portable trackers",
        "SIM based tracking for delivery men",
      ],
    },
    {
      name: "D2C",
      img: d2c,
      content: [
        "Last-mile real time tracking visibility",
        "Real time ETA updates for your customers",
        "Product shipment tracking via portable trackers",
        "SIM based tracking for delivery men",
      ],
    },
    {
      name: "Pharma and Healthcare",
      img: pharma,
      content: [
        "Tanker tracking and security",
        "Cold chain logistics",
        "Real-time ambulance tracking",
        "Ambulance efficient routing and work allocation",
        "Camera for real time monitoring",
        "Real-time tracking of expensive medical machinery",
      ],
    },
    {
      name: "Automobiles",
      img: automobiles,
      content: [
        "OEM branded vehicle diagnostics and tracking",
        "Vehicle theft tracking and protection",
        "Vision-based ADAS and Driver Monitoring System",
      ],
    },
    {
      name: "Ecommerce",
      img: ecom,
      content: [
        "Camera for Driver Monitoring",
        "Camera for Back Door Monitoring",
        "GPS Locking for theft prevention",
        "Real-time ETA updates",
        "Better incoming and outgoing stock visibility",
        "Last-mile delivery partner real time tracking",
      ],
    },
    {
      name: "Railways",
      img: railways,
      content: [
        "Wagon tracking via solar and large battery trackers",
        "Loading and unloading data via load sensors",
        "Wagon Security via GPS locking",
      ],
    },
    {
      name: "Security",
      img: security,
      content: [
        "Camera for Driver Monitoring",
        "Camera for Back Door and Cargo hold Monitoring",
        "GPS Locking for in transit theft prevention",
        "SOS Buttons for emergency escalations",
        "Door sensors for authorised unlocking information",
      ],
    },
    {
      name: "Gems and Jewellery",
      img: jewellery,
      content: ["RFID tagging to detect removal from rooms, trays"],
    },
    {
      name: "Export and Import",
      img: export_import,
      content: [
        "Vessel tracking on sea",
        "Air cargo tracking",
        "Container tracking via disposable GPS seals and trackers",
        "RFID based Security Seals",
      ],
    },
    {
      name: "Container Terminals",
      img: container_terminals,
      content: [
        "Internal trailer tracking",
        "Real-time container location tracking",
        "Reachstacker and MHE tracking and productivity",
      ],
    },
    {
      name: "Shipping Lines",
      img: shipping_lines,
      content: [
        "Container tracking using solar and large battery powered trackers",
        "Vessel Tracking using APIs",
        "Trailer tracking for better visibility on container pick ups and drop offs",
      ],
    },
    {
      name: "Oil, Gas and Energy",
      img: oil_gas_energy,
      content: [
        "Tanker tracking and security",
        "LPG cylinder tracking",
        "Lubricant track and trace",
        "Real-time routing and ETA updates for first, mid and last mile",
      ],
    },
    {
      name: "IT",
      img: it,
      content: [
        "Market expansion for sim and mobile networks",
        "Market expansion for indoor and outdoor networking",
      ],
    },
    {
      name: "Consumer Electronics",
      img: consumer_elec,
      content: [
        "Tracking of high value items in transit with GPS Locks",
        "Tracking of items using Parent Child Tracker",
        "Camera for Driver Monitoring",
        "Camera for Back Door Monitoring",
        "Cameras in cargo hold to detect theft and loading/unloading processes",
      ],
    },
    {
      name: "Mining and Metals",
      img: mining_metals,
      content: [
        "Tracking of items using Parent Child Tracker",
        "Manpower tracking and safety in mines",
        "Real time ETA updates for customers",
        "Better FG and RM delivery predictability",
        "Cameras in cargo hold to detect theft and loading/unloading processes",
      ],
    },
    {
      name: "Public Transport Networks",
      img: public_transport,
      content: [
        "Real-time Vehicle Tracking",
        "Real-time ETAs and ETDs",
        "Camera for Passenger Tracking and Safety",
      ],
    },
    {
      name: "Air Cargo",
      img: air_cargo,
      content: [
        "ULD tracking",
        "Equipment tracking",
        "International and domestic Air Cargo tracking",
        "Locking and tracking of vehicles and trucks within terminals",
      ],
    },
  ];

  return (
    <div className={styles.outer_container}>
      <StyledHeading text="Industries" />
      <div className={styles.slides_area}>
        <div className={styles.tab_container_outer}>
          {industries.map((industry, i) => {
            return (
              <div
                className={styles.tab}
                style={{
                  background: current === i ? "#ff7700" : "#ffffff",
                  color: current === i ? "#ffffff" : "#747576",
                }}
                onClick={() => setCurrent(i)}
              >
                {industry?.name}
              </div>
            );
          })}
        </div>
        {industries?.map((industry, i) => {
          if (i === current) {
            return (
              <div className={styles.info_section}>


                <div className={styles.details}>
                  <div className={styles.details_title}>
                    <span>{industry?.name}</span>
                  </div>
                  <div className={styles.details_content}>
                    {
                      window.innerWidth < 800 ?
                      <FadeInComponent duration={2000}>
                        <SlideLeftComponent duration={1000}>
                          <ul>
                            {industry?.content?.map((item, i) => {
                              return <li key={i}>{item}</li>;
                            })}
                          </ul>
                        </SlideLeftComponent>
                      </FadeInComponent>
                        
                        :
                        <ul>
                          {industry?.content?.map((item, i) => {
                            return <li key={i}>{item}</li>;
                          })}
                        </ul>
                    }

                  </div>
                  <button
                    className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
                    onClick={()=>{window.open('https://lynkit.in/ContactUs', '_blank')}}
                  >
                    Know More
                  </button>
                </div>


                {
                  window.innerWidth < 800 ?
                    <div className={styles.img_container}>
                      <img src={industry.img} />
                      <div className={styles.title_over_img}>
                        <span>{industry?.name}</span>
                      </div>
                    </div>
                    :
                    <SlideRightComponent duration={1000}>
                      <div className={styles.img_container}>
                        <img src={industry.img} />
                        <div className={styles.title_over_img}>
                          <span>{industry?.name}</span>
                        </div>
                      </div>
                    </SlideRightComponent>
                }

              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default Industries;
