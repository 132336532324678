import React from 'react';
import styles from "./styles.module.css";
import { StyledHeading } from '../../HelperComponents';

const Linkedin = () => {
  return (
    <div className={styles.main_container}>
      <div className={styles.heading}>
        <StyledHeading text="Our Updates" />
      </div>
      <div>
        <iframe src='https://www.sociablekit.com/app/embed/25467870' frameborder='0' width='100%' height='550'></iframe>
      </div>
    </div>
  )
}

export default Linkedin