import React from "react";
import heroStyles from "./heroSection.module.css";
import mainStyles from "../../main.module.css";
import mainImg from "../../assets/Outline yard Drawing.svg";
import appStore from "../../assets/hero/app_store_logo.svg";
import playStore from "../../assets/hero/play_store_logo.svg";

const HeroSection = () => {
  

  return (
    <div className={heroStyles.hero_section}>
      <div className={heroStyles.main_img_with_text_container}>
        <div className={heroStyles.main_backdrop}>
          <img src={mainImg} />
        </div>
        <div className={heroStyles.main_title_section}>
          <p>
            {`It's \ntime to make \n`}
            <span style={{color: "#FF7700"}}>your supply chains</span>
            {`\n greener, agile and efficient`}
          </p>
          <div className={heroStyles.button_container}>
            <button
              className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
              style={{ width: "max-content" }}
              onClick={()=>{window.open('https://lynkit.in/ContactUs', '_blank')}}
            >
              Book A Meeting
            </button>
            <div className={heroStyles.app_btns}>
              <div className={heroStyles.app_btn}>
                <img src={appStore} onClick={()=>{window.open('https://apps.apple.com/us/app/lynktrac/id1477021431','_blank')}}/>
              </div>
              <div className={heroStyles.app_btn}>
                <img src={playStore} onClick={()=>{window.open('https://play.google.com/store/apps/details/Lynktrac?id=com.lynkit.lynktrac&hl=en-US&pli=1', '_blank')}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default HeroSection;
