import React, { useState } from 'react'
import styles from "./styles.module.css";
import mainStyles from "../../main.module.css";
import { StyledHeading } from "../../HelperComponents";
import leftArr from '../../assets/ourAwards/leftArr.svg';
import rightArr from '../../assets/ourAwards/rightArr.svg'
import forbes30 from '../../assets/latestNews/forbes_30.jpg';
import newsLogistic from '../../assets/latestNews/newsLogistic.jpg';
import cargo_insight from '../../assets/latestNews/lynkit_vision.jpeg';
import kpmg from '../../assets/latestNews/kpmg_award.jpg';
import iws from '../../assets/latestNews/iws.png';
import times40 from '../../assets/latestNews/times40.jpg';
import startup from '../../assets/latestNews/startup.jpg';
import lynkFamily from '../../assets/latestNews/lynkFamily.jpg';
import end from '../../assets/latestNews/endtoend.jpeg';
import pik from '../../assets/latestNews/pikMyBox.png';
import block from '../../assets/latestNews/blockchain.jpeg';
import dubai from '../../assets/latestNews/dubai.jpeg';
import mundra from '../../assets/latestNews/mundra.jpg';
import korean_img from '../../assets/latestNews/korean_img.jpg';
import BITA from '../../assets/latestNews/BITA_lynkit.jpg';
import mam from '../../assets/latestNews/sadhika_mam.jpg';
import bharat from '../../assets/latestNews/bharat.png';
import eBRC from '../../assets/latestNews/eBRC.jpg';
import newspaper from '../../assets/latestNews/newspaper.png'
import bpcl_launch from '../../assets/latestNews/bpcl_launch.jpg'
import newsPresidentMeet from '../../assets/latestNews/newsPresidentMeet.png'
import award from '../../assets/latestNews/award.jpg'
import excellence from '../../assets/latestNews/excellence.jpg'
import delhitojaipur from '../../assets/latestNews/delhitojaipur.jpg'
import vamaship from '../../assets/latestNews/Vamaship.jpg'
import apace_tech from '../../assets/latestNews/lynkit_iws.jpeg'
const latestNewsData = [
    {
        img: bpcl_launch,
        title: "BPCL's \"Pure for Sure\" Revolutionizes LPG Delivery with Lynkit's AI-based Route Optimizer",
        text: "\"Pure for Sure\" by BPCL is using Lynkit's AI-based route optimizer to save time and fuel while enhancing delivery efficiency by considering traffic, weather, and distance factors.  ",
        link: "https://indianpsu.com/bpcl-bharatgas-unveils-pure-for-sure-to-revolutionize-lpg-delivery-experience/",

    },
    {
        img: forbes30,
        title: "Lynkit's co-founder and CEO featured on the Forbes 30 Under 30",
        text: "The Forbes 30 Under 30 list for 2024 is out, and Uddhav has been recognized for his innovative work in the logistics industry. He has earned a highly coveted spot on the prestigious list, a proud moment for the entire Lynkit team.",
        link: "https://www.forbesindia.com/article/30-under-30-2024/uddhav-kumar-untangling-the-logistics-mess/91653/1",

    },
    {
        img: newsPresidentMeet,
        title: "Lynkit Co-founder Ms. Sadhika invited for President's meet ",
        text: "Honourable President Smt. Droupadi Murmu invited India’s top 25 women entrepreneurs for an interaction at Rashtrapati Bhavan! What an honour! Lynkit’s very own co founder and COO, Sadhika Kumar was one of these privileged entrepreneurs",
        link: "https://presidentofindia.gov.in/press_releases/president-india-interacts-leading-women-start-ups-and-unicorns",

    },
    {
        img: newsLogistic,
        title: "Lynkit present at LEADS report launch by Sh. Piyush Goyal",
        text: " Lynkit's co-founder was called to be a part of the technical panel where the role of technology and automation in reducing logistics cost across the states was discussed.",
        link: "https://www.business-standard.com/content/specials/navigating-future-logistics-frontiers-at-leads-2023-panel-discussion-123122600335_1.html",


    },
    {
        img: apace_tech,
        title: "Lynkit's Vision for Revolutionizing Supply Chain Efficiency",
        text: "Discover our co-founder's views on how Lynkit's innovative tracking and security solutions are shaping the future of supply chain management.",
        link: "https://apacedigitalcargo.com/uddhav-kumar-lynkit-interview/",

    },
    {
        img: cargo_insight,
        title: "Lynkit enabling Logistics Transformation with AI, IoT, and Blockchain",
        text: "In this exploration, Uddhav Kumar delves into how the industry harnesses advanced technologies such as AI, IoT, and blockchain to boost end-to-end supply chain efficiency, optimise operations, and cultivate lasting partnerships in today's dynamic logistics realm.",
        link: "https://cargoinsights.co/tech-enabled-supply-chain-robustness/",

    },
    {
        img: kpmg,
        title: "KPMG's Global Tech Innovator India 2023: Lynkit  Takes the Crown",
        text: "Lynkit won KPMG's Global Tech Innovator Competition 2023 India Finale. They will represent India at Lisbon's Web Summit 2023, competing with 2300 startups worldwide.",
        link: "https://www.siliconindia.com/news/startups/kpmg-unveils-lynkit-solutions-as-victors-of-kpmg-private-enterprise-global-tech-innovator-in-india-nid-224543-cid-19.html",

    },
    {
        img: iws,
        title: "Lynkit's Showcase at The Indian Warehousing Show 2023",
        text: "3-day India Warehousing Summit concludes with a focus on Sustainability, Integrated Supply Chain & Last mile connectivity.",
        link: "https://www.nbmcw.com/news/infra-development/3-day-india-warehousing-summit-concludes-with-a-focus-on-sustainability-integrated-supply-chain-last-mile-connectivity.html",

    },
    {
        img: times40,
        title: "Lynkit's co-founder and COO, awarded with Times40under40",
        text: "Bangalore (Karnataka) [India], March 28 : The Times 40 Under 40 honored the achievements of young entrepreneurs, leaders, and change-makers who have made significant contributions in their respective fields. ",
        link: "https://www.aninews.in/news/business/business/lynkit-co-founder-sadhika-kumar-felicitated-at-times-40-under-4020230328160124/",

    },
    {
        img: startup,
        title: " Lynkit, wins National Start Up Awards 2022",
        text: "16th January 2023 marked the 7th National Start Up Day. An event was hosted in New Delhi with Commerce and Industry Minister Piyush Goyal as the Chief Guest who conferred the awards to the winners.  ",
        link: "https://www.aninews.in/news/business/business/supply-chain-automation-company-lynkit-wins-national-start-up-awards-202220230118172222/",

    },
    {
        img: award,
        title: "Lynkit wins Best Start Up (Logistics) 2022 by Entrepreneur Magazine",
        text: "Lynkit has been awarded the best start up of 2022 (logistics and fulfillment category) by Entrepreneur India. Previous winners include Fareye, Loginext and LetsTransport! ",
        link: "https://www.entrepreneurindia.com/2022/award-winners.php",

    },
    {
        img: excellence,
        title: "Lynkit's co-founder awarded with ET excellence award 2022",
        text: "Uddhav Kumar, Lynkit's co-founder was awarded the excellence award in the field of IT and Technology by Economic Times. The award was given by Mr. Madan Lal and Esha Gupta.",
        link: newspaper,

    },
    {
        img: delhitojaipur,
        title: "NHEV: 2nd phase of e-highway trial run",
        text: "As the world strives toward net zero emissions, today National Highway for Electric Vehicle , conducted the 2nd phase of the Tech Trial Run of Electric Vehicles from India Gate to Albert Hall Museum, Jaipur, Rajasthan. ",
        link: "https://www.thestatesman.com/technology/nhev-2nd-phase-of-e-highway-trial-run-starts-today-between-delhi-jaipur-1503109415.html",

    },
    {
        img: lynkFamily,
        title: "Lynkit wins top 10 blockchain startups by CIO Magazine",
        text: "Delhi-based Lynkit, a technology company excelling in providing supplychain management solutions through its six proprietary products, is on a mission to ensure painless & flawless digital transformation of supply chain. .",
        link: "https://www.cioinsiderindia.com/vendor/lynkit-supply-chain-optimization-is-a-digital-trump-card--cid-1532.html#",

    },
    {
        img: end,
        title: "End-To-End Visibility Across The Supply Chain",
        text: "Lynkit offers technology for “lynking” different legs of the supply chain to create higher efficiencies and visibility that is eventually what brings down the costs.",
        link: "https://www.maritimegateway.com/end-to-end-visibility-across-the-supply-chain/",

    },
    {
        img: pik,
        title: "PikMyBox Encourages Indian Exporters To Go Global",
        text: "PikMyBox was one of the key players deliberating on strategies for export-oriented businesses at the second edition of E-commerce Logistics Summit.",
        link: "https://www.maritimegateway.com/pikmybox-encourages-indian-exporters-to-go-global/",

    },
    {
        img: block,
        title: "Lynkit's Innovations in supply chain using Blockchain",
        text: "The unique merits of blockchain have led most the industries to leverage it and tout its ability to not only improve auditing but also increase accountability in the business world.",
        link: "https://www.ciotechoutlook.com/news/bracing-for-blockchain-impact--nid-8838-cid-135.html",

    },
    {
        img: dubai,
        title: "Lynkit Conferred With The ‘Best Companies Award’",
        text: "March 28, 2022, Dubai, UAE — At the Internet 2.0 Conference, held at the Intercontinental, Lynkit was honored with the ‘Best Companies Award’ for exceptional contributions to the technology sector..",
        link: "https://medium.com/@Internet_2.0_Dubai/lynkit-conferred-with-the-best-companies-award-at-the-internet-2-0-conference-dubai-2022-ac4d9b866a7f",

    },
    {
        img: mundra,
        title: "Lynkit launches RFID and GNSS based Terminal Automation System in Mundra",
        text: "In a new development, Mundra based Landmark CFS has launched the RFID system at its facility.",
        link: "https://indiaseatradenews.com/landmark-cfs-p-limited-launched-rfid-facility-for-exim-trade/",

    },
    {
        img: korean_img,
        title: "Incheon Port Cargo Tracking System",
        text: "Incheon Port Authority (IPA) announced on the 2nd that it had signed a three-party business agreement between the CUPIA and LYNKIT, a programming company in India, to develop a cargo tracking system in India.",
        link: "https://www.asiatoday.co.kr/view.php?key=20181202010000374",

    },
    {
        img: BITA,
        title: "Supply chain management specialist Lynkit joins BiTA",
        text: "Lynkit, the first Indian company to develop the e-consignment note on blockchain, has joined the Blockchain in Transport Alliance (BiTA). ",
        link: "https://www.freightwaves.com/news/supply-chain-management-specialist-lynkit-joins-bita",

    },
    {
        img: mam,
        title: "Lynkit: Orchestrating Efficiency into the Supply Chain",
        text: "As a full-service software company for SCM industry, Lynkit has combined the use of mature technologies like GPS, RFID and RTK along side of emerging technologies like LoRaWAN and Blockchain.",
        link: "https://startup.siliconindia.com/vendor/lynkit-orchestrating-efficiency-into-the-supply-chain-cid-12104.html",

    },
    {
        img: bharat,
        title: "Winner of “Transforming LPG commercial business” challenge",
        text: "We are extremely excited to have been selected in BPCL’s Grand Slam Challenge! We complement BPCL’s dynamic leadership for their vision to embrace innovation and encourage the start-up eco-system in India.",
        link: "https://www.bharatpetroleum.com/Bharat-Petroleum-For/Startups/Startup-Grandslam-Season.aspx",

    },
    {
        img: vamaship,
        title: "Vamaship Joins Hands with PikMyBox",
        text: "Vamaship and PikMyBox formally announced a partnership to promote e-commerce exports through India Post starting April, 2019 onwards.",
        link: "https://www.maritimegateway.com/vamaship-joins-hands-pikmybox-streamlining-end-end-logistics-exporters-strengthen-e-commerce-exports-india-post/",

    },
    {
        img: eBRC,
        title: "eBRC: Digitising export documentation",
        text: "PikMyBox and ICICI Bank have combined their digital strengths to launch a digital, paperless facility for regularization of postal bills for export.",
        link: "https://www.maritimegateway.com/ebrc-digitising-export-documentation/",

    }
]


const LatestNews = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedFeature, setSelectedFeature] = useState(latestNewsData[0]);

    const handleNext = () => {
        if (currentIndex + 3 < latestNewsData.length) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
        // console.log("next:"+currentIndex)
        setSelectedFeature(latestNewsData[currentIndex+1]);
    };

    const handlePrev = () => {

        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
        // console.log("prev:"+currentIndex)
        setSelectedFeature(latestNewsData[currentIndex-1]);
    };

    const handleSelectFeature = (index) => {
        setSelectedFeature(latestNewsData[index]);
    };
    return (
        <div className={styles.latestNews}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                }}
            >
                <StyledHeading text="Latest News" />
            </div>

            <div className={styles.containerOfBigScreen}>
                <div className={styles.main_feature}>
                    <img src={selectedFeature.img} alt={selectedFeature.title} />
                    <div className={styles.vertical_line}></div>
                    <div className={styles.main_feature_content}>
                        <div className={styles.main_feature_title_text}>
                            <p className={styles.title}>{selectedFeature.title}</p>
                            <p className={styles.main_feature_para}>{selectedFeature.text}</p>
                        </div>
                        <div className={styles.main_feature_date_btn}>
                            {/* <p className={styles.main_feature_date}>{selectedFeature.date}</p> */}
                            <button className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`} onClick={() => { window.open(selectedFeature.link, '_blank') }}>
                                Read More
                            </button>
                        </div>
                    </div>
                </div>
                <div className={styles.horizontal_line}></div> 
                <div className={styles.slider}>
                    <button className={styles.slider_left_arr} onClick={handlePrev} disabled={currentIndex === 0}>
                        <img src={leftArr} alt="" />
                    </button>
                    <div className={styles.slider_content}>
                        {latestNewsData.slice(currentIndex, currentIndex + 3).map((newsData, index) => (
                            <div key={index} className={styles.card} onClick={() => handleSelectFeature(currentIndex + index)}>
                                <div className={styles.img_holder}>
                                    <img src={newsData.img} alt={newsData.title} />
                                </div>
                                <p className={styles.slider_title}>{newsData.title}</p>
                                <div className={styles.slider_date_btn}>
                                    <button className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`} onClick={() => { window.open(newsData.link, '_blank') }}>
                                        Read More
                                    </button>
                                </div>
                            </div>

                        ))}
                    </div>
                    <button className={styles.slider_right_arr} onClick={handleNext} disabled={currentIndex + 3 >= latestNewsData.length}>
                        <img src={rightArr} alt="" />
                    </button>
                </div>

            </div>


            {/* FOR SMALLER SCREEN */}
            <div className={`${styles["LNslider-container"]} ${styles.LNsmall_screens}`}>
                <div className={`${styles["LNslider-wrapper"]}`}>
                    {latestNewsData?.map((newsData, i) => {
                        return (
                            <div className={`${styles["LNslider-item"]}`}>
                                <div className={styles["LNtitle-row"]}>
                                    <h3>{newsData.title}</h3>
                                </div>
                                {/* <hr style={{ border: "1px solid #ff7700", opacity: 1 }} /> */}
                                <div className={styles.line_Divider}> </div>
                                <p className={styles.LNslider_para}>{newsData.text}</p>
                                <img style={{ width: '100%', height: '46vw' }} src={newsData?.img} alt="" />
                                <div className={styles.slider_date_btn}>
                                    <span>{newsData.date}</span>
                                    <button className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`} onClick={() => { window.open(newsData.link, '_blank') }}>
                                        Read More
                                    </button>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}

export default LatestNews