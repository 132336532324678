import React from "react";
import styles from "./styles.module.css";
import { StyledHeading } from "../../HelperComponents";
import g2logo from "../../assets/reviews/g2-logo.png";
import starImg from "../../assets/reviews/star.png";

const RatingComponent = ({ item }) => {
  return (
    <div className={styles.rating}>
      {new Array(item?.rating)?.fill(0)?.map((star, i) => {
        return <img src={starImg} />
      })}
    </div>
  );
};

const Reviews = () => {
  const reviews = [
    {
      rating: 5,
      title: "Effective WMS with Great Results!",
      body: "Using Lynkit's Warehouse Management System has been a game-changer for us. We've achieved great visibility of our inventory, which has improved our accuracy. The system helped us reduce our order cycle time by 30%, making our operations smoother and more efficient. It's easy to use and has made a big difference in our day-to-day work. Highly recommend it for anyone looking to improve their warehouse management!",
    },
    {
      rating: 5,
      title: "Hats off to Lynktrac/Lynkit",
      body: "Good product , brilliant combination if Hardware and software.",
    },
    {
      rating: 5,
      title: "LynkTrac",
      body: "LynkTrac Locks have exceeded my expectations in every aspect. From their unparalleled security features to their user-friendly interface, they've set a new standard in the realm of locks. I highly recommend LynkTrac Locks to anyone looking to safeguard their valuables with confidence.",
    },
    {
      rating: 5,
      title: "Lynktrac experience",
      body: "Real-time monitoring of assets, fleets, and precious goods.",
    },
  ];

  return (
    <div className={styles.main_container}>
      <StyledHeading text="Lynkit Reviews" />

      <div className={styles.slider_container__outer}>
        <div className={styles.slider_container__inner}>
          {reviews?.map((item, i) => {
            return <div className={styles.slider_item} key={i}>
              <div className={styles.logo_rating}>
                <img src={g2logo} height="35px" width="35px" />
                <RatingComponent item={item} />
              </div>
              <span className={styles.title}>{item?.title}</span>
              <p className={styles.body}>{item?.body}</p>
            </div>;
          })}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
