import React from 'react';
import styles from "./styles.module.css";
import { StyledHeading } from '../../HelperComponents';
import logo from "../../assets/api/lynkit_logo.svg";
import desktopBacklines from "../../assets/api/desktop_backlines.svg";
import mobileBacklines from "../../assets/api/mobile_backlines.svg";
import rto from "../../assets/api/rto.svg";
import geofenceIcon from "../../assets/api/geofenceIcon.svg";
import openDoor from "../../assets/api/openDoor.svg";
import alerts from "../../assets/api/alerts.svg";
import installation from "../../assets/api/installation.svg";
import fois from "../../assets/api/fois.svg";
import sim_tracking from "../../assets/api/sim_tracking.svg";
import ldb from "../../assets/api/ldb.svg";
import vessel_tracking from "../../assets/api/vessel_tracking.svg";
import cargo_updates from "../../assets/api/cargo_updates.svg";
import fastag from "../../assets/api/fastag.svg";
import tabletBacklines from '../../assets/api/tablet_backlines.svg'

const Api = () => {

  const leftTiles = [
    {
      id: "rto",
      icon: rto,
      text: "Real Time Location",
    },
    {
      id: "monitoring_control",
      icon: geofenceIcon,
      text: "Geofence In And Out",
    },
    {
      id: "e2e_msp",
      icon: openDoor,
      text: "Events Such as Doors Open/Close",
    },
    {
      id: "data_transfer",
      icon: installation,
      text: "Ignition Status",
    },
    {
      id: "installation",
      icon: alerts,
      text: "Alerts Like Overstop and Overspeeding",
    },
  ]
  const rightTiles = [
    {
      id: "fois",
      icon: fois,
      text: `FOIS\n(Freight Operations Information System)`,
    },
    {
      id: "sim_tracking",
      icon: sim_tracking,
      text: "Sim Tracking",
    },
    {
      id: "ldb",
      icon: ldb,
      text: "LDB (Live Departure Boards) Container Tracking",
    },
    {
      id: "vessel_tracking",
      icon: vessel_tracking,
      text: "Vessel Tracking",
    },
    {
      id: "cargo_updates",
      icon: cargo_updates,
      text: "Air Cargo Updates",
    },
    {
      id: "fastag",
      icon: fastag,
      text: "FASTag Monitoring",
    },
  ]

  return (

    <>
    <div  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop:'10px'
        }}>
        <StyledHeading text="Lynkit's API" />
    </div >
    <div className={styles.main_container}>

      <div className={styles.api_section}>
        <div className={styles.left_side}>
          <span className={`${styles.title} ${styles.left_title}`}>Lynkit Internal API</span>
          {leftTiles.map((tile, i) => {
            return (
              <div className={styles.api_box_container} id={styles[tile.id]}>
                <div className={styles.tile} style={{ background: "#eeeeee" }}>
                  <img src={tile.icon} />
                </div>
                <p className={styles.tile_text}>{tile.text}</p>
              </div>
            )
          })}
        </div>
        <div className={styles.right_side}>
          <span className={`${styles.title} ${styles.right_title}`}>Third Party APIs</span>
          {rightTiles.map((tile, i) => {
            return (
              <div className={styles.api_box_container} id={styles[tile.id]}>
                <div className={styles.tile} style={{ background: "#fafafa" }}>
                  <img src={tile.icon} />
                </div>
                <p className={styles.tile_text}>{tile.text}</p>
              </div>
            )
          })}
        </div>
        {/* lynkit logo */}
        <div className={styles.logo_container}>
          <img src={logo} />
        </div>
        {/* background line structure */}
        <div className={styles.desktop_backlines}>
          <img src={desktopBacklines} />
        </div>
        <div className={styles.tablet_backlines}>
          <img src={tabletBacklines} />
        </div>
        <div className={styles.mobile_backlines}>
          <img src={mobileBacklines} />
        </div>
      </div>
    </div>
    
    </>
  )
}

export default Api
