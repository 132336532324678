import React from 'react'
import { StyledHeading } from '../../HelperComponents'
import Slider from './Slider'

const Testimonial = () => {
  return (
    <div style={{padding: "2.5rem 1rem", marginBottom: "2rem"}}>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <StyledHeading text="Testimonial" />
      </div>
      <div>
        <Slider />
      </div>
    </div>    
  )
}

export default Testimonial