import React from "react";
import { Helmet } from "react-helmet";
import "../Home.css";
import Footer from '../Components/Footer';
import ScrollToTop from "react-scroll-to-top";

import { Swiper, SwiperSlide } from "swiper/react";

import api1 from "../images1/api1.png";
import api2 from "../images1/api2.png";
import api3 from "../images1/api3.png";
import apic1 from "../images1/apic1.png";
import apic6 from "../images1/apic6.png";
import apic2 from "../images1/apic2.png";
import apic3 from "../images1/apic3.png";
import apic4 from "../images1/apic4.png";
import apic5 from "../images1/apic5.png";

import iec from "../images1/iec.png";
import gstin from "../images1/gstin.png";
import nsdl from "../images1/nsdl.png";
import saarthi from "../images1/saarthi.png";
import vahan from "../images1/vahan.png";
import nlds from "../images1/nlds.png";
import fastag from "../images1/fastag.png";
import shipcargo from "../images1/shipcargo.png";
import fois from "../images1/fois.png";
import aircargo from "../images1/aircargo.png";
import new_background from "../images/Vector.png";





import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {
    EffectCoverflow,
    Pagination,
    Navigation,
} from "swiper/core";
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);



const Api = () => {
    
    const apicards = [
        {
            img: apic1,
            heading: "  Saarthi Authentication for Driver License ",
            para1: " Worried about who is driving your truck? The last thing anyone wants is your goods being impounded because of a fake driver's license! With our Saarthi APIs, you don’t need to worry about that. Nor do you need to worry about long lines outside your factory and yard gates because DL verification just became a second-long process. ",
        },
        {
            img: apic2,
            heading: "  Directorate General Import of Foreign Trade (IEC) ",
            para1: " A must have for all Custom House Agents, Courier Companies and Shipping Liners! Verify the EXIM codes for any entity within seconds! You can get the Directorate General of Foreign Trade blacklisted status of the companies as well. Eg: “IEC”: AAGGFFL13 Status: “True” DGFT status: “Blacklisted” Address: 12A Rama Road, New Delhi, 110020 ",
        },
        {
            img: apic3,
            heading: " Vehicle tracking using FasTAG ",
            para1: " No GPS, No Worries! Simply by inputting in vehicle numbers, you can get FasTAG tracking via the toll booths all over the country, regardless of which issuing bank you had! You can get upto 3 days of previous history along with all the locations and timestamps! What else do you need? ",
        },
        {
            img: apic4,
            heading: " Goods and Service Tax Identification Number (GSTIN) ",
            para1: " Stop wasting time on KYC! Make it a second-long process by using our GSTIN APIs which will give you insights into their legal addresses, validity, jurisdictions as well as filing status. ",
        },
        {
            img: apic5,
            heading: " Ice Gate ",
            para1: " Our APIs give you access to data from ICE GATE and the Govt. Of India Port Community System PCS.1x to track your clearance status, port of loading, Xray results and more! This can be done on basis of shipping bills, container numbers etc.",
        },
        {
            img: apic6,
            heading: "  VAHAN Authentication for vehicle authentication ",
            para1: " Worried about your transporter updating his PUC on time? Or Insurance? Or knowing whether the vehicle has a valid RC? What about its maximum loadable weight? You don’t need to ask for any documents anymore. Pre-check the vehicle that will transport your goods using our VAHAN API! ",
        },
    ]

    const apiarray1 = [

        {
            image: api1,
            definition: "Minimize data entry",
            id: "1"
        },

        {
            image: api2,
            definition: "Reduce costs of data collection",
            id: "2"
        },
        {
            image: api3,
            definition: "Increase compliance",
            id: "3"
        },

    ]
// 
    const apis = [
		{
			title: "API for Authentication of Entities",
			items: [
				{
					title: "Import Export Code (IEC)",
					img: iec,
					description:
						"A must have for all Custom House Agents, Courier Companies, and Shipping Liners! Verify the EXIM codes for any entity within seconds. You can get the Directorate General of Foreign Trade blacklisted status of the companies as well.",
				},
				{
					title: "Goods and Service Tax Identification Number (GSTIN)",
					img: gstin,
					description:
						"Stop wasting time on KYC! Make it a second long process by using our GSTIN APIs which will give you insights into their legal addresses, validity, jurisdictions as well as filing statuses.",
				},
				{
					title: "Permanent Account Number (PAN)",
					img: nsdl,
					description:
						"One of the most basic documents we need to transact with any business is the PAN, and now with out API, you can fetch all the details and verify them with a single click.",
				},
			],
		},
		{
			title: "APIs For Authentication of Critical Transport Functions",
			items: [
				{
					title: "Saarthi Authentication for Driver License",
					img: saarthi,
					description:
						"Worried about who is driving your truck? The last thing anyone wants are your goods being impounded because of a fake driver license! With our Saarthi APIs, you don't need to wory about that. Nor do you need to worry about the long line outside your factory and yard gates because DL verification just became a second-long process.",
				},
				{
					title: "VAHAN API for Vehicle Authentication",
					img: vahan,
					description:
						"Worried about your transporter updating his PUC on time? Or Insurance? Or knowing whether the vehicle has a valid RC? What about its maximum loadable weight? You don't need to ask for any documents anymore. Pre-check the vehicle that will transport your goods using our VAHAN API!",
				},
			],
		},
		{
			title: "APIs For Tracking",
			items: [
				{
					title: "Container Tracking Via Logistics Data Bank",
					img: nlds,
					description:
						"Plan for your containers coming into your factory by using LDBs RFID based tracking APIs! Every container moving in and out of the country is now tagged with LDBs RFID tags which will give you updates about the entry and exit from ICDs, CFSs, and Ports. Certain toll roads have also been tagged by LDB!",
				},
				{
					title: "Vehicle Tracking Using FasTAG",
					img: fastag,
					description:
						"No GPS, No Worries! Simply by inputting in vehicle numbers, you can get FasTAG tracking via the toll booths all over the country, regardless of which issuing bank you had! You can get upto 3 days of previous history along with all the locations and timestamps! What else do you need?",
				},
				{
					title: "Container tracking via vessel status",
					img: shipcargo,
					description:
						"Lynkit has integrated with liners and vessels all over the world to allow you to track your containers wherever they are! As long as you know the vessel number, you don’t need to look anywhere else! ",
				},
				{
					title: "FOIS",
					img: fois,
					description:
						"Streamline Your Logistics with Real-Time Shipment Tracking: Seamlessly Integrated with FOIS API for Precision and Efficiency!",
				},
				{
					title: "Air Cargo",
					img: aircargo,
					description:
						"Worried about your air shipment? Worry no more, track your shipments and get real time update through Air Cargo API",
				},
			],
		},
	];
    return (
		<>
			<ScrollToTop id="scrolling_to_top" smooth color="black" />
			<div className="main_company">
				<Helmet>
					<title>
						Lynkit - Orchestrating Efficiency In Your Supply Chain{" "}
					</title>
					<meta
						name="description"
						content="Automate and digitally transform your supply chain with Lynkit's blockchain, telematics and IoT based logistics optimisation solutions."
					/>
					<meta
						name="keywords"
						content="Transport Management System, Rfid, Radio Frequency Identification, Bluetooth, Ble, Bluetooth low energy, Warehouse Management system, Container tracking, Container location, Gnss, Gps, Irnss, Ais 140, Global Navigation Satellite System, Real time tracking, Port automation system, Port automation, Warehouse automation, Yard automation system, Yard automation, Gate automation, Parcel tracking, Export tracking, Export documentation, Lorry receipt automation, Fleet management system, Digital invoicing system, Digital lorry receipt, Digital consignment note, E-consignment note, Digital goods receipt, Loading bay automation, Route management, Indent management, Route optimisation"
					/>
				</Helmet>

				<main>
                    <div style={{position:"relative",overflowX:'hidden'}}>
                <img src={new_background} alt="none" />
                    <div style={{position:"absolute",top:'50px'}}>
                    <section className="main_description">
						<h1 className="apiHeading">                        
							Lynkit ULIP and Third-party API Integrations
						</h1>

						<h5 className="apiSubheading">
							We offer you a wide variety of API's to
						</h5>
						<div className="apiFeatures">
                            {apiarray1.map((item, index) => {
								return (
									<div className="apibox1" key={index}>
										<img
											className="apiimg1"
											src={item.image}
											alt=""
										/>
										<h6 className="apidefinition">
											{item.definition}
										</h6>
									</div>
								);
							})}

						</div>
							<div className="apipara" style={{padding:'28px'}}>
								<p>
									Our APIs fetch data from the Govt. Of India
									portals such as DigiLocker, ULIP (Unified
									Logistics Interface Portal) and other
									private 3rd party vendors such as air cargo
									carriers, liners, vessel operators and
									banks.{" "}
								</p>
							</div>
					</section>

					{apis.map((api_type, t_index) => {
						return (
							<section className="api_section" key={t_index}>
								<div className="api_section_title">
									<h5>{api_type.title}</h5>
								</div>
								<div className="api_section_content">
									{api_type.items.map((api, a_index) => {
										const bnone =
											api_type.items.length == 2;

										return (
											<div
												className={`api_box ${
													bnone ? "b-none" : ""
												}`}
												key={a_index}
											>
												<div className="api_img">
													<img
														src={api.img}
														alt={api.title}
													/>
												</div>
												<h6>{api.title}</h6>
												<p>{api.description}</p>
											</div>
										);
									})}
								</div>
							</section>
						);
					})}
                    </div>      
                    </div>
				</main>

				<section className="sales_pitch">
					<div className="sales_heading">
						Contact{" "}
						<span className="clr-orange">sales@lynkit.in</span> to
						get started today! No set up costs, pay{" "}
						<span className="clr-orange">AS YOU GO!</span>
					</div>
					<small className="sales_note">
						* In case there is any data set you need, just let us
						know, we will find it, enable it, and make your life
						simpler
					</small>
				</section>
				{/* <div className='api-div'>
                        <div className='awardsHeading'>
                            <h1 className='apiHeading'>Lynkit ULIP and Third-party API Integrations</h1>
                        </div>
                        <div className='awardsHeading'>
                            <h5 className='apiSubheading'>We offer you a wide variety of API's to</h5>
                        </div>
                        <div className='apiFeatures'>
                            

                                {apiarray1.map((item, index) => {
                                    return (
                                        <>
                                        <div className='apibox1'>
                                            <img className='apiimg1' src={item.image} alt='' />
                                            <h6 className='apidefinition'>{item.definition}</h6>
                                        </div>                                      
                                        </>
                                        
                                    )
                                })}
                                
                                <div className='apipara'><p>Our APIs fetch data from the Govt. Of India portals such as DigiLocker, ULIP (Unified Logistics Interface Portal) 
                                     and other private 3rd party vendors such as air cargo carriers, liners, vessel operators and banks.  </p> 
                                </div>
                        </div>
                        <div className="api-swiper">
                        <Swiper
                            modules={[Navigation, Pagination]}
                            navigation={true}
                            effect={"coverflow"}
                            // slidesPerView={window.innerWidth < 320 ? 1 : "auto"}
                            loop={false}
                            centeredSlides={false}
                            initialSlide={0}
                            slidesPerView={3}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}

                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 0,
                                modifier: 1,
                                slideShadows: false
                            }}
                            pagination={{
                                clickable: true
                            }}
                        // breakpoints={{
                        //     300: {
                        //         initialSlide: 1,
                        //         slidesPerView: 3
                        //     }
                        // }}

                        // navigation= {{
                        // nextEl: '.swiper-button-next',
                        // prevEl: '.swiper-button-prev',
                        // }}
                        >

                            {apicards.map((value) => {
                                return (
                                    <SwiperSlide>
                                        <div className="api_card_1">
                                            <div className="api-card-img">
                                                <img src={value.img} alt="none" />
                                            </div>
                                            <div className="api-card-data">
                                                <h2>{value.heading}</h2>
                                                <p>{value.para1}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className="swiper-pagination"></div>
                </div> */}

				{/* <div className='api-div500'>
                        <div className='awardsHeading'>
                            <h1 className='apiHeading500'>Lynkit ULIP and Third-party API Integrations</h1>
                        </div>
                        <div className='awardsHeading'>
                            <h5 className='apiSubheading500'>We offer you a wide variety of API's to</h5>
                        </div>    
                        
                        <div className='apiFeatures500'>
                        
                            {apiarray1.map((item, index) => {
                                    return (
                                        <>
                                        <div className='apibox1500'>
                                            <img className='apiimg1500' src={item.image} alt='' />
                                            <h6 className='apidefinition500'>{item.definition}</h6>
                                        </div>                                      
                                        </>
                                        
                                    )
                                })}
                                <div className='apipara500'><p>Our APIs fetch data from the Govt. Of India portals such as DigiLocker, ULIP (Unified Logistics Interface Portal) 
                                     and other private 3rd party vendors such as air cargo carriers, liners, vessel operators and banks.  </p> 
                                </div>
                        </div>


                    <div className='apicard500'>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            effect={"coverflow"}
                            navigation={true}
                            // slidesPerView={2.5}
                            loop={false}
                            centeredSlides={false}
                            initialSlide={0}
                            spaceBetween={10}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 0,
                                modifier: 1,
                                slideShadows: false
                            }}
                            breakpoints={{
                                300: {
                                    width: 300,
                                    slidesPerView: 1,
                                    spaceBetween: 3,
                                },
                                383: {
                                    width: 383,
                                    slidesPerView: 2.08,
                                    spaceBetween: 3,
                                },
                                415: {
                                    width: 415,
                                    slidesPerView: 2.2,
                                    spaceBetween: 4,
                                },
                                500: {
                                    width: 500,
                                    slidesPerView: 2.5,
                                }
                            }}
                        >
                            {apicards.map((value) => {
                                return (
                                    <SwiperSlide>
                                        <div className="myCard">
                                            <div className="apinew_img">
                                                <img src={value.img} alt="none" />
                                            </div>
                                            <div className="apinew_data">
                                                <h2>{value.heading}</h2>
                                                <p>{value.para1}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}

                        </Swiper>
                    </div>
                    <div className="swiper-pagination"></div>
                </div> */}

				<div className="api-footer">
					<Footer />
				</div>
			</div>
		</>
	);
}

export default Api;