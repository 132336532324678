import React from "react";
import { StyledHeading } from "../../HelperComponents";
import styles from "./styles.module.css";
import account_config_icon from "../../assets/features/ac_config_icon.svg";
import account_config_main from "../../assets/features/ac_config_main.svg";
import user_mgt_icon from "../../assets/features/user_mgt_icon.svg";
import user_mgt_main from "../../assets/features/user_mgt_main.svg";
import monitoring_icon from "../../assets/features/monitoring_icon.svg";
import monitoring_main from "../../assets/features/monitoring_main.svg";
import trip_mgt_icon from "../../assets/features/trip_mgt_icon.svg";
import trip_mgt_main from "../../assets/features/trip_mgt_main.svg";
import analytics_icon from "../../assets/features/analytics_icon.svg";
import analytics_main from "../../assets/features/analytics_main.svg";
import mainStyles from "../../main.module.css";

const Tab = ({ info, active, onClick }) => {
  return (
    <div
      className={styles.feature_tab}
      style={{ background: active ? "#FF7700" : "#FFFFFF" }}
      onClick={onClick}
    >
      <img src={info?.icon} />
      <span className={styles.title}>{info?.title}</span>
    </div>
  );
};

const Features = ({ current, setCurrent }) => {
  // const [current, setCurrent] = React.useState(selectedFeature);

  const features = [
    {
      title: "Account Configuration",
      icon: account_config_icon,
      img: account_config_main,
      text: `Geofencing is available for locations to establish secure zones for starting points, via points, and various destinations. \n\nRoutes can be created and geofenced to ensure the optimal path is being followed. \n\nCreate and set your own dashboards, business and monitoring rules to highlight escalations and exceptions.`,
    },
    {
      title: "User Management",
      icon: user_mgt_icon,
      img: user_mgt_main,
      text: `Admins can create internal users and third-party vendor accounts to provide permissions based on locations and routes, facilitating seamless fleet tracking operations. Over 1 million different hierarchical combinations are available.\n\nRole-based permissions are implemented to ensure that only authorized individuals can access specific information and capabilities, preventing unauthorized access to fleet tracking data.`
    },
    {
      title: "Monitoring Dashboard",
      icon: monitoring_icon,
      img: monitoring_main,
      text: `Experience real-time visibility of all assets through an intuitive and interactive platform, enhancing fleet tracking accuracy.\n\nAutomate alert systems to reduce manual oversight and enhance efficiency in fleet tracking operations.\n\nAllow stakeholders to add incident remarks quickly in real-time to make prompt and well-informed decisions, improving fleet tracking responsiveness.`
    },
    {
      title: "Trip Management and Route Optimization",
      icon: trip_mgt_icon,
      img: trip_mgt_main,
      text: `Gain real-time insights into route and trip-wise performance analytics with AI-powered route optimization suggestions, utilizing our powerful algorithms to improve operational efficiency in fleet tracking.\nGet real-time updates on important metrics such as ETA, ETD, speed, location, and transit status, ensuring precise fleet tracking. Monitor any deviations like unwarranted stoppages to enhance operational efficiency in fleet tracking management.`
    },
    {
      title: "Analytics",
      icon: analytics_icon,
      img: analytics_main,
      text: `Access detailed data analysis covering key performance indicators such as entry processing time, parking wait time, and loading/unloading time, providing valuable insights for fleet tracking optimization.\n\nGain insights on transit performance indicators such as average speed, stoppage time, and idling, comparing with industry leaders to set a benchmark for fleet tracking solution excellence.`
    },
  ];

  return (
    <div className={styles.software_features_section}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledHeading text="Software Features" />
      </div>
      <div className={`${styles.features_outer_container} ${styles.large_screens}`}>
        <div className={styles.feature_tabs}>
          {features?.map((feature, i) => {
            return (
              <Tab
                info={feature}
                active={i === current ? true : false}
                onClick={() => setCurrent(i)}
                key={i}
              />
            );
          })}
        </div>

        <div className={styles.feature_content}>
          <div className={styles.text_content_sf}>
            <span>{features[current].title}</span>
            <p>{features[current].text}</p>
          </div>
          <div className={styles.img_content}>
            <img src={features[current].img} />
          </div>
        </div>
        <div className={styles.outline_container}>
          <button
            className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
            onClick={() => {
              window.open("https://lynkit.in/ContactUs", '_blank');
              // window.open("https://lynkit.in/ContactUs", '_self');
            }}
          >
            Request a Demo
          </button>
        </div>
      </div>
      <div className={`${styles["slider-container"]} ${styles.small_screens}`}>
        <div className={`${styles["slider-wrapper"]}`}>
          {features?.map((feature, i) => {
            return (
              <div className={`${styles["slider-item"]}`}>
                <div className={styles["title-row"]}>
                  <img src={feature.icon} />
                  <h3>{feature.title}</h3>
                </div>
                <hr style={{ border: "1px solid #ff7700", opacity: 1 }} />
                <p style={{ fontSize: "14px" }}>{feature.text}</p>
                <img src={feature?.img} alt="Geofencing" />
              </div>
            )
          })}
        </div>

      </div>
      
      <div className={styles.button_small_screen}>
          <button
            className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
            onClick={() => {
              window.open("https://lynkit.in/ContactUs", '_blank');
            }}
          >
            Request Demo
          </button>
        </div>
        <div className={styles.small_screens_button_outliner}></div>
    </div>
  );
};

export default Features;
