import React, { useEffect, useState } from "react";
import "../style.css";
import lynkit from "../images/lynkit.png";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";

const Navbar = () => {
    const [showLinks, setShowLinks] = useState(false)
    const [menubtn, setCrossbtn] = useState(<MenuRoundedIcon />)
    const [initial_state, set_initial_state] = useState("Home")
    let history = useHistory()
    let path1 = history.location.pathname

    const checklocationAndSetNavbarState = () => {
        if (path1.indexOf('/Home/products') > -1 || path1.indexOf('/Home/enterprise') > -1 || path1.indexOf('/Home') > -1) {
            route_change_and_scroll("Home");
        }
        else if (path1.indexOf("ContactUs") > -1) {
            set_initial_state("ContactUs");
        }
        else if (path1.indexOf("Advisory_Board") > -1) {
            set_initial_state("Advisory_Board");
        }
        else if (path1.indexOf("About_us") > -1) {
            set_initial_state("About_us");
        }
        else if (path1.indexOf("Careers") > -1) {
            set_initial_state("Careers");
        }
        else if (path1.indexOf("knowledge") > -1) {
            set_initial_state("knowledge");
        }
        else if (path1.indexOf("Api") > -1) {
            set_initial_state("Api");
        }
    }
    useEffect(() => {
        checklocationAndSetNavbarState();

    }, [path1]);
    const route_change_and_scroll = (path) => {
        set_initial_state(path);

        if (path == "products") {
            history.push('/Home/products');

        }

        else if (path == "enterprise") {
            history.push('/Home/enterprise');
        }
        else if (path == "Home") {
            history.push('/Home');
        }

        else if (path == "ContactUs") {
            history.push('/ContactUs');
        }

        else if (path == "About_us") {
            history.push('/About_us');
        }
        else if (path == "Advisory_Board") {
            history.push('/Advisory_Board');
        }
        else if (path == "Careers") {
            history.push('/Careers');

        }else if(path == "knowledge"){
            history.push("/knowledge-bank")
        }else if(path == "blog"){
            history.push("/blog")
        }
    }
    const changebtntype = () => {
        if (showLinks) {
            setShowLinks(!showLinks)
            setCrossbtn(<MenuRoundedIcon />)
        }
        else {
            setShowLinks(!showLinks)
            setCrossbtn(<CloseIcon />)
        }

    }

    console.log("path-----: ", path1);
    
    
    return (
        <>
            <div className="Navbar" style={{display: path1 == "/lynktrac" ? "none" : "flex"}} >
                <button className="reorder" onClick={changebtntype}> {menubtn}</button>
                <div className="leftside">
                    <a href="/Home" >
                        <img id="logo" src={lynkit} alt="logo" />
                    </a>
                    <div className="links" id={showLinks ? "hidden" : ""}>
                        <Link
                            exact to="headings"
                            spy={true}
                            smooth={true}
                            offset={-110}
                            duration={500}
                            activeClass={(initial_state == "enterprise" || initial_state == "products" || initial_state == "Home") ? 'active_class' : ''}
                            onClick={() => {
                                route_change_and_scroll("Home");
                            }} >
                            HOME
                        </Link>
                        <Link
                            exact to="main"
                            spy={true}
                            smooth={true}
                            offset={-48}
                            duration={500}
                            activeClass={(initial_state == "enterprise" || initial_state == "products" || initial_state == "Home") ? 'active_class' : ''}
                            onClick={() => {
                                route_change_and_scroll("products");
                                
                            }} >
                            PRODUCTS
                        </Link>
                        <Link
                            exact to="Enterprise_div"
                            spy={true}
                            smooth={true}
                            offset={-52}
                            duration={500}
                            activeClass={(initial_state == "enterprise" || initial_state == "products" || initial_state == "Home") ? 'active_class' : ''}
                            // className= {initial_state == "enterprise" ? "active_class " : "links_navbar1" }
                            onClick={() => route_change_and_scroll('enterprise')}
                        >
                            FOR ENTERPRISE
                        </Link>
                        <div className="dropdown" id="navbar_company">
                            <a className="links_navbar1"  > COMPANY  </a>
                            <div className="dropdown_menu">
                                <a className={initial_state == "About_us" ? "active_class_drop_1" : "dropdown1"} onClick={() => route_change_and_scroll('About_us')} >ABOUT US</a>
                                <a className={initial_state == "Advisory_Board" ? "active_class_drop_2" : "dropdown2"} onClick={() => route_change_and_scroll('Advisory_Board')}>ADVISORY BOARD</a>
                                {/* <a  className= {initial_state== "Careers" ? "active_class_drop_3" :"dropdown3" } onClick={() => route_change_and_scroll('Careers')} > CAREERS</a> */}
                            </div>
                        </div>

                        <div className="dropdown" id="navbar_knowledge">
                            <a className="links_navbar1">KNOWLEDGE BANK</a>
                            <div className="dropdown_menu">
                                <a className={initial_state == "knowledge" ? "active_class_drop_1" : "dropdown1"} onClick={() => route_change_and_scroll('knowledge')} >DID YOU KNOW</a>
                                <a className={initial_state == "blog" ? "active_class_drop_2" : "dropdown2"} onClick={() => route_change_and_scroll('blog')}>BLOG</a>
                            </div>
                        </div>
                        {/* <a className="links_navbar1" href="/Case_studies">CASE STUDIES</a> */}
                        
                        {/* <a className={initial_state === 'knowledge' ? 'active_class' : ''} onClick={() => route_change_and_scroll('knowledge')} href="/knowledge-bank">KNOWLEDGE BANK</a> */}
                        <a className={initial_state === 'Api' ? 'active_class' : ''} onClick={() => route_change_and_scroll('Api')} href="/Api">API</a>

                        <div className="mobile_div">
                            <Link
                                exact to="headings"
                                spy={true}
                                smooth={true}
                                offset={-110}
                                duration={500}
                                className="links_navbar"
                                onClick={changebtntype}
                            >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/Home">HOME</NavLink>
                            </Link>
                            <Link
                                exact to="main"
                                spy={true}
                                smooth={true}
                                offset={-50}
                                duration={500}
                                className="links_navbar"
                                onClick={changebtntype}
                            >
                                <a className="links_navbar" to="/Home/products" href="/Home/products" onClick={() => { route_change_and_scroll("products") }} >PRODUCTS</a><br />
                            </Link>
                            <Link
                                exact to="Enterprise_div"
                                spy={true}
                                smooth={true}
                                offset={-52}
                                duration={500}
                                className="links_navbar"
                                onClick={changebtntype}
                            >
                                <a className="links_navbar" to="/Home/enterprise" href="/Home/enterprise" onClick={() => { route_change_and_scroll("enterprise") }} >FOR ENTERPRISE</a><br />
                            </Link>
                            <Link spy={true} className="links_navbar" >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/About_us">ABOUT US</NavLink>
                            </Link>
                            <Link spy={true} className="links_navbar" >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/Advisory_Board">ADVISORY BOARD</NavLink>
                            </Link>
                            <Link spy={true} className="links_navbar" >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/knowledge-bank">DID YOU KNOW?</NavLink>
                            </Link>
                            <Link spy={true} className="links_navbar" >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/blog">BLOG</NavLink>
                            </Link>
                            <Link spy={true} className="links_navbar" >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/Api">API</NavLink>
                            </Link>
                            <Link spy={true} className="links_navbar" >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/ContactUs">CONTACT US</NavLink>
                            </Link>
                            <Link spy={true} className="links_navbar" >
                                <NavLink onClick={changebtntype} className="links_navbar" to="/Lynkhub">TEAM LOGIN</NavLink>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="rightside">
                    <button onClick={() => route_change_and_scroll('ContactUs')} className={initial_state == "ContactUs" ? "new_btn_color" : "contactbtn"} >CONTACT US</button>
                    <a href="https://lynkhub.io/login" target="_blank" ><button className="servicebtn" >TEAM LOGIN</button> </a>
                </div>
            </div>
        </>
    )
}
export default Navbar;