// src/Header.js
import React, { useState } from "react";
import style from "./header.module.css";
import lynktracLogo from "./lynktrac_5.webp";
// import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
// import history from "../../../../../services/history";

const features = [
  {
    label: "Account Configuration",
    link: "#features",
  },
  {
    label: "User Management",
    link: "#features",
  },
  {
    label: "Monitoring Dashboard",
    link: "#features",
  },
  {
    label: "Trip Management and Route Optimization",
    link: "#features",
  },
  {
    label: "Analytics",
    link: "#features",
  },
];

const Header = ({ setSelectedFeature }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = (index) => {
    if (openSubmenu === index) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(index);
    }
  };

  const menuItems = [
    { label: "Home", link: "#", tgt:'_self' },
    {
      label: "Key Features",
      // link: "#",
      subItems: features,
    },
    { label: "Use Cases", link: "#useCases", tgt:'_self' },
    { label: "Hardware", link: "#hardware-products", tgt:'_self' },
    { label: "About Us", link: "https://www.lynkit.in/About_us", tgt:'_blank' },
  ];

  return (
    <header className={style["header"]}>
      <div className={style["container"]}>
        <div className={style["logo"]}>
          <img src={lynktracLogo} />
        </div>
        <nav className={`${style["nav"]} ${isOpen ? style["open"] : ""}`}>
          <ul className={style["nav-list"]}>
            {menuItems.map((item, index) => (
              <li key={index} className={style["nav-item"]}>
                <a
                  target={item.tgt}
                  href={item.subItems ? null : item.link}
                  className={style["nav-link"]}
                  onClick={() => item.subItems && toggleSubmenu(index)}
                >
                  {item.label}
                  {item.subItems && (
                    <>
                      {/* {openSubmenu === index ? (
                        <KeyboardArrowUp sx={{ color: "#FF7700" }} />
                      ) : (
                        <KeyboardArrowDown sx={{ color: "#FF7700" }} />
                      )} */}
                       {openSubmenu === index ? (
                        <span style={{color:'#FF7700'}}>&#11205;</span>
                      ) : (
                        <span style={{color:'#FF7700'}}>&#11206;</span>
                      )}
                    </>
                  )}
                </a>
                {item.subItems && (
                  <ul
                    className={`${style["submenu"]} ${openSubmenu === index ? style["open"] : ""}`}
                  >
                    {item.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`${style["nav-item"]} ${style["sub-nav-item"]}`}
                        onClick={() => {
                          setSelectedFeature(subIndex);
                          setOpenSubmenu(null);
                        }}
                      >
                        <a
                          href={subItem.link}
                          className={style["nav-link"]}
                          onClick={() => {
                            setSelectedFeature(subIndex);
                            setOpenSubmenu(null);
                          }}
                        >
                          {subItem.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <button className={style["hamburger"]} onClick={toggleMenu}>
          <span className={style["hamburger-line"]}></span>
          <span className={style["hamburger-line"]}></span>
          <span className={style["hamburger-line"]}></span>
        </button>
        <div className={style.action_btn_container}>
          <button
            className={style.filled_gray}
          >
            <a href="https://lynktrac.io/login" target="_blank" className={style.header_login_btn}>Login</a>
          </button>
          <button className={style.filled_orange} onClick={()=>{window.open('https://lynkit.in/ContactUs', '_blank')}}>Request a Demo</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
