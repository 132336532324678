import React from "react";
import styles from "./helper.module.css";
import { FadeInComponent, SlideUpComponent } from "../AnimationConents";

export const GridContainer = ({ children, spacing = 0, style, ...props }) => {
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    margin: `-${spacing / 2}px`,
    ...style,
  };

  return (
    <div style={containerStyle} {...props}>
      {children}
    </div>
  );
};

export const GridItem = ({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  spacing = 0,
  style,
  className,
  ...props
}) => {
  const itemStyle = {
    padding: `${spacing / 2}px`,
    flexBasis: `100%`,
    maxWidth: `100%`,
    ...style,
  };

  return (
    <div
      className={`${styles[`grid-item grid-xs-${xs}`]} ${styles[`grid-sm-${sm}`]
        } ${styles[`grid-md-${md}`]} ${styles[`grid-lg-${lg}`]} ${styles[`grid-xl-${xl}`]
        } ${className}`}
      style={itemStyle}
      {...props}
    >
      {children}
    </div>
  );
};

export const StyledHeading = ({ text, noOrange = false, noStrike = false, light = false, ...props }) => {
  const words = text.split(" ");
  const lastWord = words.pop();
  const firstWords = words.join(" ");

  return (
    <SlideUpComponent duration={1500}>
      <FadeInComponent>

        <h1
          style={{
            // fontSize: "2.5rem",
            fontWeight: "bold",
            fontFamily: '"Fjalla One", sans-serif',
            color: light ? "#ffffff" : "#000000",
          }}
          className={styles.styled_heading}
          {...props}
        >
          {firstWords}{" "}
          <span
            style={{
              color: noOrange ? "#191818" : "#FF7700",
              position: "relative",
              fontFamily: '"Fjalla One", sans-serif',
            }}
          >
            {lastWord}
            {!noStrike && (
              <svg
                style={{
                  position: "absolute",
                  bottom: "-0.2em",
                  right: "0px",
                  width: "2em",
                  height: "0.5em",
                }}
                viewBox="0 0 100 10"
                preserveAspectRatio="none"
              >
                <path
                  d="M0,10 Q50,0 100,10"
                  stroke={light ? "#ffffff" : "#191818"}
                  strokeWidth="2"
                  fill="transparent"
                />
              </svg>
            )}
          </span>
        </h1>
      </FadeInComponent>
    </SlideUpComponent>

  );
};
