import React from 'react';
import "../posts.css";
import ScrollToTop from "react-scroll-to-top";
import Footer from '../Components/Footer';
import febbg from "../assets/feb/febbg.png";
import jan from "../images/1.png";
import mayBg from "../assets/may/maybg.png";
import mayPost from "../assets/may/mayPost.jpg";
import bg from "../assets/april/1.png";
import junebg from "../assets/june/junebg.png";
import iotbg from "../images/iotbg.png"

import octbg from "../images/octbg.png";
import oneoct from "../assets/oct21/1.png";
import twooct from "../assets/oct21/2.png";
import threeoct from "../assets/oct21/3.png";
import fouroct from "../assets/oct21/4.png";
import fiveoct from "../assets/oct21/5.png";
import sixoct from "../assets/oct21/6.png";
import sevenoct from "../assets/oct21/7.png";

import decbg from "../images/decbg.png";
import onedec from "../assets/dec21/1.png";
import twodec from "../assets/dec21/2.png";
import threedec from "../assets/dec21/3.png";
import fourdec from "../assets/dec21/4.png";
import fivedec from "../assets/dec21/5.png";
import sixdec from "../assets/dec21/6.png";

import novbg from "../assets/nov21/novbg.png";
import onenov from "../assets/nov21/1.png";
import twonov from "../assets/nov21/2.png";
import threenov from "../assets/nov21/3.png";
import fournov from "../assets/nov21/4.png";
import fivenov from "../assets/nov21/5.png";
import sixnov from "../assets/nov21/6.png";

import blockchainbg from "../images/blockchainbg.png";
import onejan from "../assets/jan/1.png";
import twojan from "../assets/jan/2.png";
import threejan from "../assets/jan/3.png";
import fourjan from "../assets/jan/4.png";
import fivejan from "../assets/jan/5.png";
import sixjan from "../assets/jan/6.png";
import sevenjan from "../assets/jan/7.png";
import eightjan from "../assets/jan/8.png";


import onefeb from "../assets/feb/1.png";
import twofeb from "../assets/feb/2.png";
import threefeb from "../assets/feb/3.png";
import fourfeb from "../assets/feb/4.png";

import onemarch from "../assets/march/1.png";
import twomarch from "../assets/march/2.png";
import lynkitlogo from "../assets/march/3.png";
import threemarch from "../assets/march/4.png";

import one from "../assets/april/2.png";
import teeth from "../assets/april/teeth.png";
import two from "../assets/april/3.png";

import onemay from "../assets/may/1.png";
import twomay from "../assets/may/2.png";
import threemay from "../assets/may/3.png";
import fourmay from "../assets/may/4.png";

import onejune from "../assets/june/1.png";
import twojune from "../assets/june/2.png";

import july1a from "../assets/july/july1a.png";
import july1b from "../assets/july/ic.png";
import july1c from "../assets/july/july1c.png";
import chipset from "../assets/july/chipset.png";
import july33 from "../assets/july/july33.png";


import oned from "../assets/sept/oned.png";
import twod from "../assets/sept/twod.png";
import qr from "../assets/sept/qrcode.png";
import datamatrix from "../assets/sept/datamatrix.png";
import P417 from "../assets/sept/PDF417.png";
import maxicode from "../assets/sept/maxicode.png";
import bar1 from "../assets/sept/barcode1.png";
import bar2 from "../assets/sept/barcode2.png";
import bar3 from "../assets/sept/barcode3.png";
import bar4 from "../assets/sept/barcode4.png";
import sep11 from "../assets/sept/sepl11.png";
import sep12 from "../assets/sept/sepl12.png";
import maycar from "../assets/may23/maycar.png";
import prodpost from "../assets/jan/prodpost.png";
import commpost1 from "../assets/may23/commpost1.png";
import nfcimg from "../assets/may23/nfcimg.png";
import iotimg5 from "../assets/may23/iotimg5.png";
import personpng from "../assets/may23/personpng.png";
import personbg from "../assets/may23/personbg.png";
import semicon from "../assets/may23/semicon.png";

const Posts = (props) => {
    const data = props.location.state.detail
    return (
        <>
            <div className='postMain'>
                <ScrollToTop id="scrolling_to_top" smooth color="black" />
                {data === 'October' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Global Navigation Satellite System (GNSS)</span>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Oct, 2021</span> */}
                                        <span className='postSpecial'>Exploring the Terms: GPS or GNSS </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            GPS is actually not a generic term! The generic term is GNSS (Global Navigation Satellite System). <span className='colorWhite'>GPS is the name of the satellite system that has been deployed by the USA. Here are some of the other Satellite constellations deployed by the rest of the world.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>

                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img src={oneoct} alt='' className='oneoct' />
                                </div>
                            </div>


                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img src={fouroct} alt='' className='fouroct' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Oct, 2021</span> */}
                                        <span className='postSpecial'>Acquiring 3D GNSS Location </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            To acquire an accurate 3D location or a “GNSS fix”, the GNSS receiver on your device requires a connection with atleast 4 satellites !<span className='colorWhite'> Why do we need 4? We have 4 variables to solve; Longitude, Latitude, altitude and lastly the variable of time! Each satellite provides an equation for one variable.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Oct, 2021</span> */}
                                        <span className='postSpecial'> Assisted GPS for Enhanced Positioning </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            A-GPS or assisted GPS is a method by which some devices are able to get a gps fix much easier than other devices. The device connects to A-GNSS servers or cell phone towers to extract positioning data.<span className='colorWhite'> Mobile towers and servers have inbuilt GNSS receivers which will continuously receive signals from satellites to process the information which is then "assists" the receiver on the devices with more accurate and quicker readings.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img className='fiveoct' src={fiveoct} alt='' />
                                </div>
                            </div>


                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img src={sixoct} alt='' className='sixoct' />
                                    <img src={sevenoct} alt='' className='sevenoct' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Oct, 2021</span> */}
                                        <span className='postSpecial'>GAGAN & IRNSS: India's Navigation </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            GAGAN, <span className='colorWhite'>developed jointly by ISRO and Airport Authority of India stands for GPS-aided GEO augmented navigation. It is a </span> 3 <span className='colorWhite'>satelite system which augments signals received from USA's GPS constellation.</span><br /><br />
                                            IRNSS<span className='colorWhite'> on the other hand is like USA's GPS - An </span>independent <span className='colorWhite'>satelite constellation system which works independently. However unlike GPS it is not global, it is only for locations in India and the region extending up to 1500 km from its boundary.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>                           

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2023</span> */}
                                        <span className='postSpecial'>Vehicle GPS Revolution </span>
                                    </div>
                                    <div className='postTop'>
                                    <span>
                                        <span className='colorWhite'>In </span> 1990, <span className='colorWhite'>the </span>Mazda Eunos Cosmo <span className='colorWhite'> became the first production car with built-in </span>
                                               GPS-navigation system.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>

                                <div className='postsLeft'>
                                <img src={octbg} alt='' className='imgabs' />
                                <img src={maycar} alt='' className='may-car' />
                                
                                </div>
                            </div>



                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'November' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>RFID</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img src={onenov} alt='' className='onenov' />
                                    <img src={twonov} alt='' className='twonov' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Nov, 2021</span> */}
                                        <span className='postSpecial'>RFID: Active and Passive </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Passive tags <span className='colorWhite'> have no battery. As the name implies, passive tags wait for a signal from an RFID reader, i.e. draw power from the reader itself. </span><br /><br />
                                            Active RFID tags<span className='colorWhite'> have a transmitter and their own power source, which is usually in the form of a non replaceble battery - they do not need to draw power from the reader. Active RFID tags have a much longer reading range but thus are also much more expensive!</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Nov, 2021</span> */}
                                        <span className='postSpecial'>Know RFID Antennas </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Rfid antennas  <span className='colorWhite'>are available for two variants-: Linear or Circular polarisation.</span>  <span className='colorWhite'>Linear polarisation occurs when the reader's electromagnetic waves are broadcasted by the antenna on a single and narrow plane which may be either vertical or horizontal. Antennas with circular polarisation transmit electromagnetic fields in a rotational manner, covering both planes; visibly it would resemble a conical or corkscrew-like motion.</span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img className='fournov' src={threenov} alt='' />
                                    <span className='polar'>Circular Polarisation</span>
                                    <img className='threenov' src={fournov} alt='' />
                                    <span className='polardown'>Linear Polarisation</span>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img src={fivenov} alt='' className='fivenov' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Nov, 2021</span> */}
                                        <span className='postSpecial'>EPC vs TID </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            An Electronic Product Code (EPC) <span className='colorWhite'>is a number written to a passive UHF RFID transponder by anyone who owns the tag or the asset the tag may be placed on.</span><br /><br />
                                            A transponder ID (TID) <span className='colorWhite'>on the other hand is a globally unique number written to a transponder/RFID IC by the manufacturer of the IC itself. This number cannot be changed, hence many times preferred over EPC for use cases.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Nov, 2021</span> */}
                                        <span className='postSpecial'>On-metal RFID Solutions </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Anti-Metal RFID tags  <span className='colorWhite'>or</span> on-metal RFID tags  <span className='colorWhite'>are needed when applying passive RFID tags to any conductive or metallic material! You would know from Vol. 2 Ep. 1 that passive RFID tags transmit data only by absorbing a readers power via their antenna. However metallic surfaces reflect the electromagnetic fields and thus disable the tag from being able to absorb any.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img className='sixnov' src={sixnov} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={personbg} alt='' className='imgabs' />
                                    <img src={personpng} alt='' className='person-png' />
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Jun, 2023</span> */}
                                        <span className='postSpecial'> The Birth of RFID  </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>In </span>1973, <span className='colorWhite'>the </span>
                                                Mario Cardullo's device <span className='colorWhite'>was the first true ancestor of modern </span>
                                                RFID, <span className='colorWhite'>as it was a passive radio transponder </span>16 bit memory.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'December' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Logistics</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Dec, 2021</span> */}
                                        <span className='postSpecial'>Explore the Terms </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            FCL - <span className='colorWhite'> Full Container Load</span> <br /><br />
                                            TEU - <span className='colorWhite'> Twenty Foot equivalent unit</span> <br /><br />
                                            LCL - <span className='colorWhite'> Less than Container Load</span> <br /><br />
                                            FEU - <span className='colorWhite'> Forty Foot equivalent unit</span> <br />
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img className='twodec' src={twodec} alt='' />
                                    <img className='threedec' src={threedec} alt='' />
                                    <img className='fourdec' src={fourdec} alt='' />
                                    <img className='fivedec' src={fivedec} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={sixdec} alt='' className='sixdec' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Dec, 2021</span> */}
                                        <span className='postSpecial'>Enhancing the Logistics </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Fulfilment centres are more than just a warehouse. They provide retailers and e-commerce players a solution to ship products more quickly, cheaply and efficiently to their customers by:
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            1. Storing product closer to areas where deliveries will be made.<br /><br />
                                            2. Individually packing and labelling the products.<br /><br />
                                            3. Coordinating with multiple carries for quicker and cheaper deliveries to end customers.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Dec, 2021</span> */}
                                        <span className='postSpecial'>Unprecedented Rise </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>SINCE DEC 2019</span> SHIPPING <span className='colorWhite'>COSTS HAVE INCREASED BY</span> 6X
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img src={fourjan} alt='' className='fourjan' />
                                    <div className='dotted'></div>
                                    <img src={fivejan} alt='' className='fivejan' />
                                </div>
                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'January' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Blockchain</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img src={onejan} alt='' className='imgjan1' />
                                    <img src={twojan} alt='' className='imgjan2' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Jan, 2022</span> */}
                                        <span className='postSpecial'>Private and Public Blockchains </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Private blockchains <span className='colorWhite'>are those where only a single entity or a group of entities (this may be then termed as a consortium blockchain) manage and control the network. They alone decide the rules of who gets to view and make certain transactions.</span>
                                        </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Public blockchains <span className='colorWhite'>on the other hand give everyone the same rights and permissions to view and write - all are treated as an equal! These blockchains also have "explorers” where anyone can go to validate and view transactions.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Jan, 2022</span> */}
                                        <span className='postSpecial'>Automating the Workflow </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Smart contracts are lines of code used to automate workflows allowing certain actions to be executed automatically when specific conditions are met. They are much like regular contracts and all transactions are recorded on a blockchain ledger and thus immutable.  <span className='colorWhite'>Usually smart contracts are written using Node.js, Golang (for Hyperledger), or Solidity (for Ethereum). At Lynkit, we use Node.js to write all our smart contracts for companies looking to automate freight contract implementation .</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img className='sixjan' src={sixjan} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img src={sevenjan} alt='' className='sevenjan' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Jan, 2022</span> */}
                                        <span className='postSpecial'>Understanding POW </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Proof of work (POW)<span className='colorWhite'>, is the original consensus algorithm in Blockchain networks (Bitcoin, Ethereum are both based on POW), where crypto-miners compete to solve a complex problem using computing, such a global demand surge for GPUs!). Those who can solve these equations get to add new blocks on the chain and are rewarded with digital currency for their work.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Jan, 2022</span> */}
                                        <span className='postSpecial'>Understanding POS </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Proof of stake(POS) <span className='colorWhite'>is a consensus mechanism used to validate transactions where miners stake their crypto- coins to be able to add new blocks of Reward transactions to the chain. Proof of stake uses a process by which 1 miner is "randomly” chosen to validate the block.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img className='eightjan' src={eightjan} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'February' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Open Source Technologies</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Feb, 2022</span> */}
                                        <span className='postSpecial'>Popular Frontend Technologies </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'> Both </span> React.js  <span className='colorWhite'>and</span> Angular <span className='colorWhite'>are frontend technologies, but the main difference is that React is a library whereas Angular is a framework. Secondly, Angular supports only client side rendering, whereas React supports server side rendering as well.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={onemarch} alt='' className='img1march' />
                                    <img className='img2march' src={twomarch} alt='' />
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img src={onefeb} alt='' className='img1feb' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Feb, 2022</span> */}
                                        <span className='postSpecial'>Embracing the Unstructured Data </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            M stands for MongoDB -- this is the open source database we use, where everything is stored in json documents. It’s a non-relational db (meaning a non sql database), which means we don’t need to define our schemas before building the application.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            MongoDB is used by EA Games, Adobe, SAP, Toyota, Cisco, and many others due to its ability to handle unstructured data, allowing for quick run time changesm and flexibility.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Feb, 2022</span> */}
                                        <span className='postSpecial'>Understanding the Terminology </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            M<span className='colorWhite'>ongodb - Database</span><br />
                                            E<span className='colorWhite'>xpress.js- framework for the backend</span><br />
                                            A<span className='colorWhite'>ngular/</span>R<span className='colorWhite'>eact.js- frontend</span> <br />
                                            N<span className='colorWhite'>ode.js- backend</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img className='threemarch' src={twofeb} alt='' />
                                </div>
                            </div>





                            {/* <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img src={threefeb} alt='' className='img3feb' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Feb, 2022</span>
                                        <span className='postSpecial'>( #Did you know  ) </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Lynkit smart contract are the future.

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            78 % <span className='lighter'> of the industry selected contract implementation is their biggest pain point in supply chain management. </span>
                                        </span>
                                    </div>
                                </div>
                            </div> */}

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img className='img4feb' src={fourfeb} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>Feb, 2022</span> */}
                                        <span className='postSpecial'>Know Express.js </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Express.js is a server side/web application framework for Node.js which is in turn a runtime environment. Node has the following advantages;
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            1. Event-driven - when node.js doesn't have anything to do it sleeps<br /><br />

                                            2. Asynchronous - does not need to wait for earlier command to finish, and can execute multiple at once.<br /><br />

                                            3. JavaScript - is used to write Node js APIs and hence creates a JavaScript everywhere scenario .<br />
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'March' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Lynkit's Products</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>March, 2022</span> */}
                                        <span className='postSpecial'>PikMyBox </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>Lynkit's first product was</span> PikMyBox! <span className='colorWhite'> It started as a SaaS based platform to manage documentation for export shipments of e-commerce and D2C exporters!</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            PMB's moto is <span className='colorOrange'> "Made in India, Sold to the World"</span> and <span className='colorOrange'> "Exporting Made Simple"</span>
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img className='threemarch' src={threemarch} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>March, 2022</span> */}
                                        <span className='postSpecial'>Lynkit Logo </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>Lynkit's monogram is actually the letters,</span> L,Y,N,K,I,T <span className='colorWhite'>superimposed into a square! Each of these letters has been arranged in symmetrical manner to have a single point of intersection. Our monogram represents not only the "Iynkage" of these letters but also accuracy and precision!</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img className='lynkitlogo' src={lynkitlogo} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img src={threefeb} alt='' className='img3feb' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>March, 2022</span> */}
                                        <span className='postSpecial'>Lynkit Smart Contract </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Lynkit smart contracts are the future.

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            78 % <span className='lighter'> of the industry selected contract implementation is their biggest pain point in supply chain management. </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>March, 2022</span> */}
                                        <span className='postSpecial'>Lynkit WMS </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Lynkit's <span className='colorWhite'> WMS has helped our customers reduce time spent on locating their products by</span> 85%

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img className='threejan' src={threejan} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img src={prodpost} alt='' className='block-img' />
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Jan, 2023</span> */}
                                        <span className='postSpecial'>Lynksign </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        E-SIGN DONGLES ARE THE PAST.
                                        BLOCKCHAIN BASED SIGNING IS THE FUTURE.<br />
                                        <span className='colorWhite fontSize'>STAY AHEAD WITH LYNKIT.</span><br />
                                        <span className='colorWhite'>
                                        <ul>
                                            <li>Secure any document or image</li>
                                            <li>Capture location via your devices actual location</li>
                                            <li>Autocapture signing time</li>
                                            <li>OTP authenticated email as signing credential</li>
                                            <li>Verify document anywhere any time with private key</li>
                                        </ul>
                                        </span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'April' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Bluetooth and Bluetooth Low Energy (BLE)</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={bg} alt='' className='imgabs' />
                                    <img src={one} alt='' className='img1' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>April, 2022</span> */}
                                        <span className='postSpecial'>Bluetooth MAC Address </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'> A Bluetooth Public address sometimes referred to as a Bluetooth MAC address, is a 48-bit value that uniquely identifies a Bluetooth device.</span> A Bluetooth Public address is a global fixed address that must be registered with the IEEE. The MAC address or public address is split into two 24 bit parts.<br /><br />
                                            <span className='colorOrange fontSize'> Company ID </span> - which is allocated by the IEEE<br />
                                            <br />
                                            <span className='colorOrange fontSize'>Company assigned </span> - which is assigned by the manufacturer
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>April, 2022</span> */}
                                        <span className='postSpecial'>Origin of Bluetooth </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            In 1996, three industry leaders, Intel, Ericsson, and Nokia, decided to collaborate to create a standard protocol for a short distance radio frequency which could be used for multi-device communication. King Harald who was nicknamed <span className='colorOrange fontSize'>“Bluetooth”</span>, due to his actual dead tooth which was blue in colour, was responsible for uniting the Scandinavian countries. It was thus decided to codename this communication protocol Bluetooth since the vision was to Unite the entire electronics, mobile and PC industry on the basis of this technology.
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={bg} alt='' className='imgabs' />
                                    <img className='img2' src={teeth} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={bg} alt='' className='imgabs' />
                                    <img src={two} alt='' className='img3' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>April, 2022</span> */}
                                        <span className='postSpecial'>Beacons and Gateways </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Bluetooth based location triangulation requires <span className='colorOrange fontSize'> beacons</span> and <span className='colorOrange fontSize'>gateways</span>. The beacons transmit their ID and signal strength to the gateways which then send the data to the application using LAN, Ethernet or WiFi!
                                        </span>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'May' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Export and Import Logistics</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2022</span> */}
                                        <span className='postSpecial'>E-BRC: Collaboration </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            A Bank Realisation Certificate or BRC is a certificate issued by Banks for commercial export shipments. This is used as a confirmation that the exporter has received payment and is submitted to the Directorate General of Foreign Trade or DGFT.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            ICICI bank and PikMyBox have collaborated to facilitate the process of an electronic BRC which greatly reduces the time and cost involved in the submission of this document to DGFT. This new process has done away with the need of physically submitting the documents.
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={onedec} alt='' className='decimg' />
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={onemay} alt='' className='img1abs' />
                                    <img className='img2abs' src={twomay} alt='' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2022</span> */}
                                        <span className='postSpecial'>IEC: Import Export Code Simplification </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'>IEC - Stands for the Import Export Code, a number mandatory for any company importing or exporting goods from India via any mode.</span> The IEC used to be a 10 digit unique numeric identifier in the pre-GST era. Post the introduction of GST, the Directorate General of Foreign Trade, one of the governing bodies of import and export regulations in India, directed all new IECs would be the same as the company PAN number. All older numeric only IECs are now also to be migrated to the new system of following the PAN number.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2022</span> */}
                                        <span className='postSpecial'>Customs House Agents: Trade Facilitators </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            CHA stands for <span className='colorOrange fontSize'> Customs House Agent</span>. CHAs are actually individuals who are issued licenses which authorizes them to file shipping documents on behalf of the company that is exporting or importing the goods for the purpose of customs clearance. A company that provides clearing services, has to have a CHA on their roles, and use the CHA license of this individual. CHAs/clearing companies play an integral role in the EXIM trade.

                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img className='img2may' src={threemay} alt='' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={fourmay} alt='' className='img3may' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2022</span> */}
                                        <span className='postSpecial'>HSN Codes: Product Classification </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            HSN stands for <span className='colorOrange fontSize'>Harmonised System of Nomenclature</span>. This is a <span className='colorOrange fontSize'>2</span> to <span className='colorOrange fontSize'>8</span> digit code that classifies various products and even services. The HSN code contains <span className='colorOrange fontSize'>21</span> sections which are further divided into <span className='colorOrange fontSize'>99</span> chapters. The HSN was developed by the World Customs Organization (WCO) back in 1988; thus in EXIM trade, both importing and exporting countries are able to use this common nomenclature system to recognize the nature of goods and services.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2022</span> */}
                                        <span className='postSpecial'>Shipping Bill: Export Authorization </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'>  A shipping bill is the document that is submitted to the customs authorities to allow the export of goods to another country.</span><br /><br /> It is filed by the CHA (Custom House Agent) on behalf of the exporter on the customs portal (online) or offline in certain stations which have not been brought online.
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={junebg} alt='' className='imgabs' />
                                    <img src={onejune} alt='' className='img1june' />
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={junebg} alt='' className='imgabs' />
                                    <img className='img2june' style={{ right: '0px', position: 'relative' }} src={twojune} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2022</span> */}
                                        <span className='postSpecial'>EGM: Export Manifest Reporting </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            EGM stands for export general manifest and is filled by the carrier of the goods. In case of ea transport it is filled by the shipping liner, or by the agents.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Similarly in case of air it is filled by the airline. When goods are being exported via road, the EGM is called as the 'export report' and is filled again by the carrier.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2022</span> */}
                                        <span className='postSpecial'>IGM: Import Manifest Reporting </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Just like documents are filled by the carriers for
                                            exporting, they also need to file documents when
                                            Importing! <span className='colorOrange fontSize'>Import General Manifest (IGM)</span> is filed in
                                            case of <span className='colorOrange fontSize'>sea/air carriers</span> and an <span className='colorOrange fontSize'>Import report</span> in case
                                            of land movement. Once imported, documents are
                                            then filed by the consignee or his agent. This
                                            document contains details regarding the
                                            description of goods (HSN) and indicates details of
                                            import duties to be paid.
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={junebg} alt='' className='imgabs' />
                                    <img src={mayPost} alt='' className='img1june' />
                                </div>
                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}

                {data === 'july' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>IC's and Semiconductors</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>July, 2022</span> */}
                                        <span className='postSpecial'>Semiconductors & Integrated Circuits </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Semiconductor refers to a material whose conductivity is between a <span className='colorOrange fontSize'>conductor</span>  and an  <span className='colorOrange fontSize'>insulator </span>. Common semiconductor materials include silicon, germanium.<br /><br />
                                            IC stands for integrated circuit and is a small electronic component which combines<span className='colorOrange fontSize'> transistors, resistors, capacitors</span> etc. on wafers/sheets made of semiconductors (such as silica) to form a circuit. Each wafer can produce multiple ICs of the same type.<br /><br />
                                            Modules on the other hand, have <span className='colorOrange fontSize'>single</span> or <span className='colorOrange fontSize'>multiple ICs</span> packed together with other components such as RAM and Flash together. Modules are thus <span className='colorOrange fontSize'>larger</span> than ICs.
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft july3'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={july1a} alt='' className='julya' />
                                    <img src={july1b} alt='' className='julyb' />
                                    <img src={july1c} alt='' className='julyc' />
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={chipset} alt='' className='chipImg' />
                                    <span className='chipspan'>SOC Chipset</span>
                                    <span className='chipspan1'>SIP Chipset</span>
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>July, 2022</span> */}
                                        <span className='postSpecial'>SoC vs SiP: Integration & Packaging </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            The main difference between a System-on-a-Chip and System-in-a-package is the manufacturing process and the <span className='colorOrange fontSize'>space/area</span> that is occupied by them on a printed circuit board.<br /><br />
                                            A System-on-a-Chip brings together various components having different functions together on a single IC. These components include <span className='colorOrange fontSize'>RAM, ROM, CPU, Flash, GPU, I/O Ports</span>. This means that all the ICs are placed next to each other and spread over a wider area.<br /><br />
                                            SiP's do not contain all the components in one single IC but instead has multiple chips <span className='colorOrange fontSize'> stacked</span>  on top of one another.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>July, 2022</span> */}
                                        <span className='postSpecial'>CPU, MPU, MCU: Explained </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Central processing unit or CPU is the <span className='colorOrange fontSize'> brain </span> of a computer and is in charge of performing all the main functions on the basis of the inputs and commands it gets.<br /><br />
                                            The Microprocessor or MPU contains a <span className='colorOrange fontSize'> CPU </span> and some other components such as cache in a single unit.<br /><br />
                                            MCUs or Microcontrollers are even a level above! They contain CPUs along with <span className='colorOrange fontSize'> Cache, Flash, RAM and other peripherals </span>. This means that MCUs can handle most of the functionalities related to processing internally.
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft july3'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={july33} alt='' className='july33' />
                                    <span className='centre'>Central Processing<br />Unit</span>
                                    <span className='micro'>Micro Processor<br />Unit</span>
                                    <span className='macro'>Micro Controller<br />Unit</span>
                                </div>
                            </div>
                        
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={nfcimg} alt='' className='nfc-img' />
                                    
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Jun, 2023</span> */}
                                        <span className='postSpecial'>NFC Technology </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'>Sony and NXP </span>Semiconductors developed NFC technology in the early <span className='colorOrange fontSize'>2000s,</span>
                                            which later bacame standardized by the NFC Forum. It's used in various industries due to its reliability, including Automotive, Medical, Health & Fitness, and Gaming & Entertainent.

                                              
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>May, 2023</span> */}
                                        <span className='postSpecial'>TSMC's Water Consumption </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            It takes <span className='colorOrange fontSize'>5600L of water </span>to produce a single <span className='colorOrange fontSize'>8 inch wafer of ICs </span>(Integrated Circuit)! <br /> <br />
                                             This is perhaps why <span className='colorOrange fontSize'>TSMC </span>in Taiwan consumes <span className='colorOrange fontSize'>almost a third </span> of the entire water supply in the city they are in.
                                       
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft july3'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={semicon} alt='' className='semicon' />
                                    
                                </div>
                            </div>
                            

                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'sept' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Barcodes, QR codes, 1D and 2D codes</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight postRightAuto'>
                                    <div className='monthPost'>
                                        {/* <span>September, 2022</span> */}
                                        <span className='postSpecial'>1D vs 2D Barcodes: Comparison </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom '>
                                        <span className='codesSpan'>
                                            <span className='colorOrange fontSize underlineMe'> 1D Barcode </span><br />
                                            * 1D barcodes or one-dimensional barcodes are <span className='colorOrange fontSize'> linear </span> barcodes.<br />
                                            * They consist of <span className='colorOrange fontSize'> vertical lines </span> of varying widths with specific gaps resulting in a particular pattern.<br />
                                            * Encode Information <span className='colorOrange fontSize'> Horizontally </span> and cannot be read from any angle and can store <span className='colorOrange fontSize'> 85 </span> characters.<br />
                                            {/* <br /> */}
                                            <span className='colorOrange fontSize underlineMe'> 2D Barcode </span><br />
                                            * 2D barcodes are more <span className='colorOrange fontSize'>complex</span>.<br />
                                            * They encode data generally in <span className='colorOrange fontSize'> square or rectangular </span> patterns of two dimensions.<br />
                                            * Encode Information <span className='colorOrange fontSize'> Horizontally & vertically</span> and can be read from any angle and can store <span className='colorOrange fontSize'>7,089</span> characters<br />
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft july3'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={oned} alt='' className='oned' />
                                    <img src={twod} alt='' className='twod' />
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={qr} alt='' className='sep1' />
                                    <img src={datamatrix} alt='' className='sep2' />
                                    <img src={P417} alt='' className='sep3' />
                                    <img src={maxicode} alt='' className='sep411' />
                                    <span className='qrSpan'>QR Code</span>
                                    <span className='matrixspan'>Data Matrix</span>
                                    <span className='pdfspan'>PDF 417</span>
                                    <span className='maxspan'>MaxiCode</span>
                                </div>
                                <div className='postsRight postRightAuto'>
                                    <div className='monthPost'>
                                        {/* <span>September, 2022</span> */}
                                        <span className='postSpecial'>Comparing 2D Barcode Types </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span className='codesSpan'>
                                            <span className='colorOrange fontSize underlineMe'> QR Code </span><br />
                                            The QR code has full (360 degrees) reading features. Each QR code has <span className='colorOrange fontSize'>three finder</span> patterns located in three corners of the QR code.<br />
                                            Best Use:- Retail, Event & Marketing<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> DataMatrix</span><br />
                                            Data Matrix barcodes are adaptable in size. This is the <span className='colorOrange fontSize'>smallest</span> among all 2D barcodes. <br />
                                            Best Use:- Electronic components, industrial engineering, Medical<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> PDF 417 </span><br />
                                            Each PDF417 barcode consists of <span className='colorOrange fontSize'>three to 90 rows </span> and a single row is essentially the equivalent of a small 1D barcode. PDF417 specifies that each pattern in the code consists of <span className='colorOrange fontSize'> four bars and spaces </span>, and each pattern is to be <span className='colorOrange fontSize'>17 units</span> long. This is how we get the "417" part of the name.<br />
                                            Best Use:- Airline boarding passes, Postage, Shipping<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> MaxiCode </span><br />
                                            MaxiCode is a <span className='colorOrange fontSize'> two-dimensional</span> code developed by United Parcel Service (UPS). It's suitable for tracking and managing shipments of packages.<br />
                                            Best Use:- Transportation<br /><br />



                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsRight postRightAuto'>
                                    <div className='monthPost'>
                                        {/* <span>September, 2022</span> */}
                                        <span className='postSpecial'>Barcode Symbologies </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span className='codesSpan'>
                                            <span className='colorOrange fontSize underlineMe'> Code 39 </span><br />
                                            Code 39 is a variable-length, <span className='colorOrange fontSize'>discrete barcode</span> symbology. Also, it is often known as Alpha39, Code 3 of 9, Code 3/9, Type 39, USS 39.<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> Code 128 </span><br />
                                            The Code 128 character set includes the digits <span className='colorOrange fontSize'> zero</span> through <span className='colorOrange fontSize'>nine</span>, the letters <span className='colorOrange fontSize'>  A-Z </span> and all standarad <span className='colorOrange fontSize'>ASCII</span>  symbols.<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> EAN 13 </span><br />
                                            The EAN-13 barcode is used more than any other barcode worldwide. The EAN-13 barcode enocdes a  <span className='colorOrange fontSize'>GTIN-13</span>  and is used to identify individual item at the retail point of sale.<br /><br />

                                            <span className='colorOrange fontSize underlineMe'>ISBN</span><br />
                                            Around the globe, books are identified with an <span className='colorOrange fontSize'> ISBN </span>  and corresponding ISBN code. This standardized book ISBN number allows publishers to seamlessly interact with retailers.<br /><br />
                                        </span>
                                    </div>
                                </div>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={bar1} alt='' className='sep1' />
                                    <img src={bar2} alt='' className='sep2' />
                                    <img src={bar3} alt='' className='sep3' />
                                    <img src={bar4} alt='' className='sep44' />
                                    <span className='code39'>Code 39</span>
                                    <span className='code128'>Code 128</span>
                                    <span className='ean13'>EAN 13</span>
                                    <span className='isbn'>ISBN</span>
                                </div>

                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={sep11} alt='' className='sep11' />
                                    <img src={sep12} alt='' className='sep12' />
                                    <span className='sku'>SKU</span>
                                    <span className='upc'>UPC</span>
                                </div>
                                <div className='postsRight postRightAuto'>
                                    <div className='monthPost'>
                                        {/* <span>September, 2022</span> */}
                                        <span className='postSpecial'>SKU vs UPC/EAN</span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span className='codesSpan'>
                                            <span className='colorOrange fontSize underlineMe'>SKU</span><br />
                                            * Stock keeping unit<br />
                                            * Unique for each retailer<br />
                                            * Varies in length<br />
                                            * Identifies product traits<br />
                                            * Numeric or Alphanumeric Used <br />
                                            * Generated by retailers as per their convenience<br />

                                            <span className='colorOrange fontSize underlineMe'>UPC/EAN</span><br />
                                            * Universal product code/European<br />
                                            * Consistent across retailers<br />
                                            * 12 digits (UPC)/ 13 digits (EAN)<br />
                                            * Identifies manufacturer and products<br />
                                            * Supply chain between multiple parties<br />
                                        </span>
                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''
                }
                {data === 'june' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Internet of Things (IoT)</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={iotbg} alt='' className='imgabs' />
                                    <img src={iotimg5} alt='' className='iotimg5' />
                                    
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Feb, 2023</span> */}
                                        <span className='postSpecial'>What is IoT? </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        IoT or Internet of things is a wide spectrum of instruments! It usually refers to devices which are connected to the internet and send information of the surroundings or the paramaeters of the machine they are connected with, remotely to an application/server. <br /> <br />
                                        <span className='colorWhite'>Iot devices have brought huge efficiencies into industries all over the world by reducing the need of manual interventions, on site surveillance, manual data entries, and making data available instantly and in real time. </span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={iotbg} alt='' className='imgabs' />
                                    <img src={commpost1} alt='' className='comm-img' />
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        {/* <span>Mar, 2023</span> */}
                                        <span className='postSpecial'>Wireless Connections Overview </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        Wireless technologies <span className='colorWhite'>are essential (but not necessary) for the functioning of </span> 
                                        IoT devices. <span className='colorWhite'>WiFi and Cellular networks are just the beginning! There is a bigger world out there...</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}                

            </div>

            <div className='postMainToggle'>
                <ScrollToTop id="scrolling_to_top" smooth color="black" />
                {data === 'October' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Global Navigation Satellite System (GNSS)</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img src={oneoct} alt='' className='oneoct' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Oct, 2021</span>
                                        <span className='postSpecial'>Exploring the Terms: GPS or GNSS </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            GPS is actually not a generic term! The generic term is GNSS (Global Navigation Satellite System). <span className='colorWhite'>GPS is the name of the satellite system that has been deployed by the USA. Here are some of the other Satellite constellations deployed by the rest of the world.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img src={fouroct} alt='' className='fouroct' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Oct, 2021</span>
                                        <span className='postSpecial'>Acquiring 3D GNSS Location </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            To acquire an accurate 3D location or a “GNSS fix”, the GNSS receiver on your device requires a connection with atleast 4 satellites !<span className='colorWhite'> Why do we need 4? We have 4 variables to solve; Longitude, Latitude, altitude and lastly the variable of time! Each satellite provides an equation for one variable.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img className='fiveoct' src={fiveoct} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Oct, 2021</span>
                                        <span className='postSpecial'> Assisted GPS for Enhanced Positioning  </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            A-GPS or assisted GPS is a method by which some devices are able to get a gps fix much easier than other devices. The device connects to A-GNSS servers or cell phone towers to extract positioning data.<span className='colorWhite'> Mobile towers and servers have inbuilt GNSS receivers which will continuously receive signals from satellites to process the information which is then "assists" the receiver on the devices with more accurate and quicker readings.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={octbg} alt='' className='imgabs' />
                                    <img src={sixoct} alt='' className='sixoct' />
                                    <img src={sevenoct} alt='' className='sevenoct' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Oct, 2021</span>
                                        <span className='postSpecial'>GAGAN & IRNSS: India's Navigation </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            GAGAN, <span className='colorWhite'>developed jointly by ISRO and Airport Authority of India stands for GPS-aided GEO augmented navigation. It is a </span> 3 <span className='colorWhite'>satelite system which augments signals received from USA's GPS constellation.</span><br /><br />
                                            IRNSS<span className='colorWhite'> on the other hand is like USA's GPS - An </span>independent <span className='colorWhite'>satelite constellation system which works independently. However unlike GPS it is not global, it is only for locations in India and the region extending up to 1500 km from its boundary.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                            <div className='postsLeft'>
                                <img src={octbg} alt='' className='imgabs' />
                                <img src={maycar} alt='' className='may-car' />
                                
                                </div>
                                <div className='postsRight alignRight'>
                                <div className='monthPost'>
                                        <span>May, 2023</span>
                                        <span className='postSpecial'>Vehicle GPS Revolution </span>
                                    </div>
                                    <div className='postTop'>
                                    <span>
                                        <span className='colorWhite'>In </span> 1990, <span className='colorWhite'>the </span>Mazda Eunos Cosmo <span className='colorWhite'> became the first production car with built-in </span>
                                               GPS-navigation system.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'November' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>RFID</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img src={onenov} alt='' className='onenov' />
                                    <img src={twonov} alt='' className='twonov' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Nov, 2021</span>
                                        <span className='postSpecial'>RFID: Active and Passive </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Passive tags <span className='colorWhite'> have no battery. As the name implies, passive tags wait for a signal from an RFID reader, i.e. draw power from the reader itself. </span><br /><br />
                                            Active RFID tags<span className='colorWhite'> have a transmitter and their own power source, which is usually in the form of a non replaceble battery - they do not need to draw power from the reader. Active RFID tags have a much longer reading range but thus are also much more expensive!</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img className='fournov' src={threenov} alt='' />
                                    <span className='polar'>Circular Polarisation</span>
                                    <img className='threenov' src={fournov} alt='' />
                                    <span className='polardown'>Linear Polarisation</span>
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Nov, 2021</span>
                                        <span className='postSpecial'>Exploring RFID Antennas </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Rfid antennas  <span className='colorWhite'>are available for two variants-: Linear or Circular polarisation.</span>  <span className='colorWhite'>Linear polarisation occurs when the reader's electromagnetic waves are broadcasted by the antenna on a single and narrow plane which may be either vertical or horizontal. Antennas with circular polarisation transmit electromagnetic fields in a rotational manner, covering both planes; visibly it would resemble a conical or corkscrew-like motion.</span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img src={fivenov} alt='' className='fivenov' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Nov, 2021</span>
                                        <span className='postSpecial'>EPC vs TID </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            An Electronic Product Code (EPC) <span className='colorWhite'>is a number written to a passive UHF RFID transponder by anyone who owns the tag or the asset the tag may be placed on.</span><br /><br />
                                            A transponder ID (TID) <span className='colorWhite'>on the other hand is a globally unique number written to a transponder/RFID IC by the manufacturer of the IC itself. This number cannot be changed, hence many times preferred over EPC for use cases.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={novbg} alt='' className='imgabs' />
                                    <img className='sixnov' src={sixnov} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Nov, 2021</span>
                                        <span className='postSpecial'>On-metal RFID Solutions  </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Anti-Metal RFID tags  <span className='colorWhite'>or</span> on-metal RFID tags  <span className='colorWhite'>are needed when applying passive RFID tags to any conductive or metallic material! You would know from Vol. 2 Ep. 1 that passive RFID tags transmit data only by absorbing a readers power via their antenna. However metallic surfaces reflect the electromagnetic fields and thus disable the tag from being able to absorb any.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={personbg} alt='' className='imgabs' />
                                    <img src={personpng} alt='' className='person-png' />
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Jun, 2023</span>
                                        <span className='postSpecial'>The Birth of RFID </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>In </span>1973, <span className='colorWhite'>the </span>
                                                Mario Cardullo's device <span className='colorWhite'>was the first true ancestor of modern </span>
                                                RFID, <span className='colorWhite'>as it was a passive radio transponder </span>16 bit memory.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            


                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'December' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Logistics</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img className='twodec' src={twodec} alt='' />
                                    <img className='threedec' src={threedec} alt='' />
                                    <img className='fourdec' src={fourdec} alt='' />
                                    <img className='fivedec' src={fivedec} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Dec, 2021</span>
                                        <span className='postSpecial'>Explore the Terms </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            FCL - <span className='colorWhite'> Full Container Load</span> <br /><br />
                                            TEU - <span className='colorWhite'> Twenty Foot equivalent unit</span> <br /><br />
                                            LCL - <span className='colorWhite'> Less than Container Load</span> <br /><br />
                                            FEU - <span className='colorWhite'> Forty Foot equivalent unit</span> <br />
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={sixdec} alt='' className='sixdec' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Dec, 2021</span>
                                        <span className='postSpecial'>Enhancing the Logistics </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Fulfilment centres are more than just a warehouse. They provide retailers and e-commerce players a solution to ship products more quickly, cheaply and efficiently to their customers by:
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            1. Storing product closer to areas where deliveries will be made.<br /><br />
                                            2. Individually packing and labelling the products.<br /><br />
                                            3. Coordinating with multiple carries for quicker and cheaper deliveries to end customers.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img src={fourjan} alt='' className='fourjan' />
                                    {/* <div c?lassName='dotted'></div> */}
                                    <img src={fivejan} alt='' className='fivejan' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Dec, 2021</span>
                                        <span className='postSpecial'>Unprecedented Rise </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>SINCE DEC 2019</span> SHIPPING <span className='colorWhite'>COSTS HAVE INCREASED BY</span> 6X
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'January' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Blockchain</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img src={onejan} alt='' className='imgjan1' />
                                    <img src={twojan} alt='' className='imgjan2' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Jan, 2022</span>
                                        <span className='postSpecial'>Private and Public Blockchains </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Private blockchains <span className='colorWhite'>are those where only a single entity or a group of entities (this may be then termed as a consortium blockchain) manage and control the network. They alone decide the rules of who gets to view and make certain transactions.</span>
                                        </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Public blockchains <span className='colorWhite'>on the other hand give everyone the same rights and permissions to view and write - all are treated as an equal! These blockchains also have "explorers” where anyone can go to validate and view transactions.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img className='sixjan' src={sixjan} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Jan, 2022</span>
                                        <span className='postSpecial'>Automating the Workflow </span>
                                    </div>

                                    <div className='postTop'>
                                        <span>
                                            Smart contracts are lines of code used to automate workflows allowing certain actions to be executed automatically when specific conditions are met. They are much like regular contracts and all transactions are recorded on a blockchain ledger and thus immutable.  <span className='colorWhite'>Usually smart contracts are written using Node.js, Golang (for Hyperledger), or Solidity (for Ethereum). At Lynkit, we use Node.js to write all our smart contracts for companies looking to automate freight contract implementation.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img src={sevenjan} alt='' className='sevenjan' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Jan, 2022</span>
                                        <span className='postSpecial'>Understanding POW </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Proof of work (POW)<span className='colorWhite'>, is the original consensus algorithm in Blockchain networks (Bitcoin, Ethereum are both based on POW), where crypto-miners compete to solve a complex problem using computing, such a global demand surge for GPUs!). Those who can solve these equations get to add new blocks on the chain and are rewarded with digital currency for their work.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img className='eightjan' src={eightjan} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Jan, 2022</span>
                                        <span className='postSpecial'>Understanding POS </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Proof of stake(POS) <span className='colorWhite'>is a consensus mechanism used to validate transactions where miners stake their crypto- coins to be able to add new blocks of Reward transactions to the chain. Proof of stake uses a process by which 1 miner is "randomly” chosen to validate the block.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'February' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Open Source Technologies</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={onemarch} alt='' className='img1march' />
                                    <img className='img2march' src={twomarch} alt='' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Feb, 2022</span>
                                        <span className='postSpecial'>Popular Frontend Technologies </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'> Both </span> React.js  <span className='colorWhite'>and</span> Angular <span className='colorWhite'>are frontend technologies, but the main difference is that React is a library whereas Angular is a framework. Secondly, Angular supports only client side rendering, whereas React supports server side rendering as well.</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img src={onefeb} alt='' className='img1feb' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Feb, 2022</span>
                                        <span className='postSpecial'>Embracing the Unstructured Data </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            M stands for MongoDB -- this is the open source database we use, where everything is stored in json documents. It’s a non-relational db (meaning a non sql database), which means we don’t need to define our schemas before building the application.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            MongoDB is used by EA Games, Adobe, SAP, Toyota, Cisco, and many others due to its ability to handle unstructured data, allowing for quick run time changesm and flexibility.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img className='threemarch' src={twofeb} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Feb, 2022</span>
                                        <span className='postSpecial'>Understanding the Terminology </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            M<span className='colorWhite'>ongodb - Database</span><br />
                                            E<span className='colorWhite'>xpress.js- framework for the backend</span><br />
                                            A<span className='colorWhite'>ngular/</span>R<span className='colorWhite'>eact.js- frontend</span> <br />
                                            N<span className='colorWhite'>ode.js- backend</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img className='img4feb alingnCentre' src={fourfeb} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>Feb, 2022</span>
                                        <span className='postSpecial'>Know Express.js </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Express.js is a server side/web application framework for Node.js which is in turn a runtime environment. Node has the following advantages;
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            1. Event-driven - when node.js doesn't have anything to do it sleeps<br /><br />

                                            2. Asynchronous - does not need to wait for earlier command to finish, and can execute multiple at once.<br /><br />

                                            3. JavaScript - is used to write Node js APIs and hence creates a JavaScript everywhere scenario .<br />
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'March' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Lynkit's Products</span>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img className='threemarch' src={threemarch} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>March, 2022</span>
                                        <span className='postSpecial'>PikMyBox </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>Lynkit's first product was</span> PikMyBox! <span className='colorWhite'> It started as a SaaS based platform to manage documentation for export shipments of e-commerce and D2C exporters!</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            PMB's moto is <span className='colorOrange'> "Made in India, Sold to the World"</span> and <span className='colorOrange'> "Exporting Made Simple"</span>
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img className='lynkitlogo' src={lynkitlogo} alt='' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>March, 2022</span>
                                        <span className='postSpecial'>Lynkit Logo </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            <span className='colorWhite'>Lynkit's monogram is actually the letters,</span> L,Y,N,K,I,T <span className='colorWhite'>superimposed into a square! Each of these letters has been arranged in symmetrical manner to have a single point of intersection. Our monogram represents not only the "Iynkage" of these letters but also accuracy and precision!</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img src={threefeb} alt='' className='img3feb' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>March, 2022</span>
                                        <span className='postSpecial'>Lynkit Smart Contract </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Lynkit smart contracts are the future.

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            78 % <span className='lighter'> of the industry selected contract implementation is their biggest pain point in supply chain management. </span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={blockchainbg} alt='' className='imgabs' />
                                    <img className='threejan' src={threejan} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>March, 2022</span>
                                        <span className='postSpecial'>Lynkit WMS </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            Lynkit's <span className='colorWhite'> WMS has helped our customers reduce time spent on locating their products by</span> 85%
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={febbg} alt='' className='imgabs' />
                                    <img src={prodpost} alt='' className='block-img' />
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Jan, 2023</span>
                                        <span className='postSpecial'>Lynksign </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        E-SIGN DONGLES ARE THE PAST.
                                        BLOCKCHAIN BASED SIGNING IS THE FUTURE.<br />
                                        <span className='colorWhite fontSize'>STAY AHEAD WITH LYNKIT.</span><br />
                                        
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                    <span className='colorWhite'>
                                        
                                            • Secure any document or image <br />
                                            • Capture location via your devices actual location<br />
                                            • Autocapture signing time<br />
                                            • OTP authenticated email as signing credential<br />
                                            • Verify document anywhere any time with private key<br />
                                        
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'April' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Bluetooth and Bluetooth Low Energy (BLE)</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={bg} alt='' className='imgabs' />
                                    <img src={one} alt='' className='img1' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>April, 2022</span>
                                        <span className='postSpecial'>Bluetooth MAC Address </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'> A Bluetooth Public address sometimes referred to as a Bluetooth MAC address, is a 48-bit value that uniquely identifies a Bluetooth device.</span> A Bluetooth Public address is a global fixed address that must be registered with the IEEE. The MAC address or public address is split into two 24 bit parts.<br /><br />
                                            <span className='colorOrange fontSize'> Company ID </span> - which is allocated by the IEEE<br />
                                            <br />
                                            <span className='colorOrange fontSize'>Company assigned </span> - which is assigned by the manufacturer
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={bg} alt='' className='imgabs' />
                                    <img className='img2' src={teeth} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>April, 2022</span>
                                        <span className='postSpecial'>Origin of Bluetooth </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            In 1996, three industry leaders, Intel, Ericsson, and Nokia, decided to collaborate to create a standard protocol for a short distance radio frequency which could be used for multi-device communication. King Harald who was nicknamed <span className='colorOrange fontSize'>“Bluetooth”</span>, due to his actual dead tooth which was blue in colour, was responsible for uniting the Scandinavian countries. It was thus decided to codename this communication protocol Bluetooth since the vision was to Unite the entire electronics, mobile and PC industry on the basis of this technology.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={bg} alt='' className='imgabs' />
                                    <img src={two} alt='' className='img3' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>April, 2022</span>
                                        <span className='postSpecial'>Beacons and Gateways </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Bluetooth based location triangulation requires <span className='colorOrange fontSize'> beacons</span> and <span className='colorOrange fontSize'>gateways</span>. The beacons transmit their ID and signal strength to the gateways which then send the data to the application using LAN, Ethernet or WiFi!
                                        </span>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'May' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Export and Import Logistics and Paperwork</span>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={onedec} alt='' className='decimg' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>May, 2022</span>
                                        <span className='postSpecial'>E-BRC: Collaboration </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            A Bank Realisation Certificate or BRC is a certificate issued by Banks for commercial export shipments. This is used as a confirmation that the exporter has received payment and is submitted to the Directorate General of Foreign Trade or DGFT.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            ICICI bank and PikMyBox have collaborated to facilitate the process of an electronic BRC which greatly reduces the time and cost involved in the submission of this document to DGFT. This new process has done away with the need of physically submitting the documents.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={onemay} alt='' className='img1abs' />
                                    <img className='img2abs' src={twomay} alt='' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>May, 2022</span>
                                        <span className='postSpecial'>IEC: Import Export Code Simplification </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'>IEC - Stands for the Import Export Code, a number mandatory for any company importing or exporting goods from India via any mode.</span> The IEC used to be a 10 digit unique numeric identifier in the pre-GST era. Post the introduction of GST, the Directorate General of Foreign Trade, one of the governing bodies of import and export regulations in India, directed all new IECs would be the same as the company PAN number. All older numeric only IECs are now also to be migrated to the new system of following the PAN number.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img className='img2may' src={threemay} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>May, 2022</span>
                                        <span className='postSpecial'>CHA's: Trade Facilitators</span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            CHA stands for <span className='colorOrange fontSize'> Customs House Agent</span>. CHAs are actually individuals who are issued licenses which authorizes them to file shipping documents on behalf of the company that is exporting or importing the goods for the purpose of customs clearance. A company that provides clearing services, has to have a CHA on their roles, and use the CHA license of this individual. CHAs/clearing companies play an integral role in the EXIM trade.

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={fourmay} alt='' className='img3may' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>May, 2022</span>
                                        <span className='postSpecial'>HSN Codes: Product Classification </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            HSN stands for <span className='colorOrange fontSize'>Harmonised System of Nomenclature</span>. This is a <span className='colorOrange fontSize'>2</span> to <span className='colorOrange fontSize'>8</span> digit code that classifies various products and even services. The HSN code contains <span className='colorOrange fontSize'>21</span> sections which are further divided into <span className='colorOrange fontSize'>99</span> chapters. The HSN was developed by the World Customs Organization (WCO) back in 1988; thus in EXIM trade, both importing and exporting countries are able to use this common nomenclature system to recognize the nature of goods and services.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={junebg} alt='' className='imgabs' />
                                    <img src={onejune} alt='' className='img1june' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>May, 2022</span>
                                        <span className='postSpecial'>Shipping Bill: Export Authorization </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'>  A shipping bill is the document that is submitted to the customs authorities to allow the export of goods to another country.</span><br /><br /> It is filed by the CHA (Custom House Agent) on behalf of the exporter on the customs portal (online) or offline in certain stations which have not been brought online.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={junebg} alt='' className='imgabs' />
                                    <img className='img2june' src={twojune} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>May, 2022</span>
                                        <span className='postSpecial'>EGM: Export Manifest Reporting</span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                            EGM stands for export general manifest and is filled by the carrier of the goods. In case of ea transport it is filled by the shipping liner, or by the agents.
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Similarly in case of air it is filled by the airline. When goods are being exported via road, the EGM is called as the 'export report' and is filled again by the carrier.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={junebg} alt='' className='imgabs' />
                                    <img className='img2june' src={mayPost} alt='' />
                                </div>
                                <div className='postsRight alignRight'>
                                    <div className='monthPost'>
                                        <span>May, 2022</span>
                                        <span className='postSpecial'>IGM: Import Manifest Reporting</span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span>
                                                Just like documents are filled by the carriers for
                                                exporting, they also need to file documents when
                                                Importing! <span className='colorOrange fontSize'>Import General Manifest (IGM)</span> is filed in
                                                case of <span className='colorOrange fontSize'>sea/air carriers</span> and an <span className='colorOrange fontSize'>Import report</span> in case
                                                of land movement. Once imported, documents are
                                                then filed by the consignee or his agent. This
                                                document contains details regarding the
                                                description of goods (HSN) and indicates details of
                                                import duties to be paid.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                {data === 'july' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>IC's and Semiconductors</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft july3'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={july1a} alt='' className='julya' />
                                    <img src={july1b} alt='' className='julyb' />
                                    <img src={july1c} alt='' className='julyc' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>July, 2022</span>
                                        <span className='postSpecial'>Semiconductors & Integrated Circuits </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Semiconductor refers to a material whose conductivity is between a <span className='colorOrange fontSize'>conductor</span>  and an  <span className='colorOrange fontSize'>insulator </span>. Common semiconductor materials include silicon, germanium.<br /><br />
                                            IC stands for integrated circuit and is a small electronic component which combines<span className='colorOrange fontSize'> transistors, resistors, capacitors</span> etc. on wafers/sheets made of semiconductors (such as silica) to form a circuit. Each wafer can produce multiple ICs of the same type.<br /><br />
                                            Modules on the other hand, have <span className='colorOrange fontSize'>single</span> or <span className='colorOrange fontSize'>multiple ICs</span> packed together with other components such as RAM and Flash together. Modules are thus <span className='colorOrange fontSize'>larger</span> than ICs.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={chipset} alt='' className='chipImg' />
                                    <span className='chipspan' style={{fontSize:'11px'}} >SOC Chipset</span>
                                    <span className='chipspan1' style={{fontSize:'11px'}}>SIP Chipset</span>
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>July, 2022</span>
                                        <span className='postSpecial'>SoC vs SiP: Integration & Packaging </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            The main difference between a System-on-a-Chip and System-in-a-package is the manufacturing process and the <span className='colorOrange fontSize'>space/area</span> that is occupied by them on a printed circuit board.<br /><br />
                                            A System-on-a-Chip brings together various components having different functions together on a single IC. These components include <span className='colorOrange fontSize'>RAM, ROM, CPU, Flash, GPU, I/O Ports</span>. This means that all the ICs are placed next to each other and spread over a wider area.<br /><br />
                                            SiP's do not contain all the components in one single IC but instead has multiple chips <span className='colorOrange fontSize'> stacked</span>  on top of one another.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft july3'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={july33} alt='' className='july33' />
                                    <span className='centre' style={{fontSize:'9px'}}>Central Processing<br />Unit</span>
                                    <span className='micro' style={{fontSize:'9px'}}>Micro Processor<br />Unit</span>
                                    <span className='macro' style={{fontSize:'9px'}}>Micro Controller<br />Unit</span>
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>July, 2022</span>
                                        <span className='postSpecial'>CPU, MPU, MCU: Explained </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            Central processing unit or CPU is the <span className='colorOrange fontSize'> brain </span> of a computer and is in charge of performing all the main functions on the basis of the inputs and commands it gets.<br /><br />
                                            The Microprocessor or MPU contains a <span className='colorOrange fontSize'> CPU </span> and some other components such as cache in a single unit.<br /><br />
                                            MCUs or Microcontrollers are even a level above! They contain CPUs along with <span className='colorOrange fontSize'> Cache, Flash, RAM and other peripherals </span>. This means that MCUs can handle most of the functionalities related to processing internally.
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={nfcimg} alt='' className='nfc-img' />
                                    
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Jun, 2023</span>
                                        <span className='postSpecial'>NFC Technology </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize'>Sony and NXP </span>Semiconductors developed NFC technology in the early <span className='colorOrange fontSize'>2000s,</span>
                                            which later bacame standardized by the NFC Forum. It's used in various industries due to its reliability, including Automotive, Medical, Health & Fitness, and Gaming & Entertainent.

                                              
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={semicon} alt='' className='semicon' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>May, 2023</span>
                                        <span className='postSpecial'>TSMC's Water Consumption </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                    <span>
                                        It takes <span className='colorOrange fontSize'>5600L of water </span>to produce a single <span className='colorOrange fontSize'>8 inch wafer of ICs </span>(Integrated Circuit)! <br /> <br />
                                        This is perhaps why <span className='colorOrange fontSize'>TSMC </span>in Taiwan consumes <span className='colorOrange fontSize'>almost a third </span> of the entire water supply in the city they are in.
                                       
                                    </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}

                {data === 'sept' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Barcodes, QR codes, 1D and 2D codes</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft july3'>
                                    <img src={decbg} alt='' className='imgabs' />
                                    <img src={oned} alt='' className='oned' />
                                    <img src={twod} alt='' className='twod' />
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>September, 2022</span>
                                        <span className='postSpecial'>1D vs 2D Barcodes: Comparison </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>
                                            <span className='colorOrange fontSize underlineMe'> 1D Barcode </span><br />
                                            * 1D barcodes or one-dimensional barcodes are <span className='colorOrange fontSize'> linear </span> barcodes.<br />
                                            * They consist of <span className='colorOrange fontSize'> vertical lines </span> of varying widths with specific gaps resulting in a particular pattern.<br />
                                            * Encode Information <span className='colorOrange fontSize'> Horizontally </span> and cannot be read from any angle and can store <span className='colorOrange fontSize'> 85 </span> characters.<br />
                                            {/* <br /> */}
                                            <span className='colorOrange fontSize underlineMe'> 2D Barcode </span><br />
                                            * 2D barcodes are more <span className='colorOrange fontSize'>complex</span>.<br />
                                            * They encode data generally in <span className='colorOrange fontSize'> square or rectangular </span> patterns of two dimensions.<br />
                                            * Encode Information <span className='colorOrange fontSize'> Horizontally & vertically</span> and can be read from any angle and can store <span className='colorOrange fontSize'>7,089</span> characters<br />
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={qr} alt='' className='sep1' />
                                    <img src={datamatrix} alt='' className='sep2' />
                                    <img src={P417} alt='' className='sep3' />
                                    <img src={maxicode} alt='' className='sep411' />
                                    {/* <span className='qrSpan'>QR Code</span>
                                    <span className='matrixspan'>Data Matrix</span>
                                    <span className='pdfspan'>PDF 417</span>
                                    <span className='maxspan'>MaxiCode</span> */}
                                </div>
                                <div className='postsRight postRightAuto'>
                                    <div className='monthPost'>
                                        <span>September, 2022</span>
                                        <span className='postSpecial'>Comparing 2D Barcode Types</span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span className='codesSpan'>
                                            <span className='colorOrange fontSize underlineMe'> QR Code </span><br />
                                            The QR code has full (360 degrees) reading features. Each QR code has <span className='colorOrange fontSize'>three finder</span> patterns located in three corners of the QR code.<br />
                                            Best Use:- Retail, Event & Marketing<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> DataMatrix</span><br />
                                            Data Matrix barcodes are adaptable in size. This is the <span className='colorOrange fontSize'>smallest</span> among all 2D barcodes. <br />
                                            Best Use:- Electronic components, industrial engineering, Medical<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> PDF 417 </span><br />
                                            Each PDF417 barcode consists of <span className='colorOrange fontSize'>three to 90 rows </span> and a single row is essentially the equivalent of a small 1D barcode. PDF417 specifies that each pattern in the code consists of <span className='colorOrange fontSize'> four bars and spaces </span>, and each pattern is to be <span className='colorOrange fontSize'>17 units</span> long. This is how we get the "417" part of the name.<br />
                                            Best Use:- Airline boarding passes, Postage, Shipping<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> MaxiCode </span><br />
                                            MaxiCode is a <span className='colorOrange fontSize'> two-dimensional</span> code developed by United Parcel Service (UPS). It's suitable for tracking and managing shipments of packages.<br />
                                            Best Use:- Transportation<br /><br />



                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={bar1} alt='' className='sep1' />
                                    <img src={bar2} alt='' className='sep2' />
                                    <img src={bar3} alt='' className='sep3' />
                                    <img src={bar4} alt='' className='sep44' />
                                    {/* <span className='code39'>Code 39</span>
                                    <span className='code128'>Code 128</span>
                                    <span className='ean13'>EAN 13</span>
                                    <span className='isbn'>ISBN</span> */}
                                </div>
                                <div className='postsRight postRightAuto'>
                                    <div className='monthPost'>
                                        <span>September, 2022</span>
                                        <span className='postSpecial'>Barcode Symbologies </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span className='codesSpan'>
                                            <span className='colorOrange fontSize underlineMe'> Code 39 </span><br />
                                            Code 39 is a variable-length, <span className='colorOrange fontSize'>discrete barcode</span> symbology. Also, it is often known as Alpha39, Code 3 of 9, Code 3/9, Type 39, USS 39.<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> Code 128 </span><br />
                                            The Code 128 character set includes the digits <span className='colorOrange fontSize'> zero</span> through <span className='colorOrange fontSize'>nine</span>, the letters <span className='colorOrange fontSize'>  A-Z </span> and all standarad <span className='colorOrange fontSize'>ASCII</span>  symbols.<br /><br />

                                            <span className='colorOrange fontSize underlineMe'> EAN 13 </span><br />
                                            The EAN-13 barcode is used more than any other barcode worldwide. The EAN-13 barcode enocdes a  <span className='colorOrange fontSize'>GTIN-13</span>  and is used to identify individual item at the retail point of sale.<br /><br />

                                            <span className='colorOrange fontSize underlineMe'>ISBN</span><br />
                                            Around the globe, books are identified with an <span className='colorOrange fontSize'> ISBN </span>  and corresponding ISBN code. This standardized book ISBN number allows publishers to seamlessly interact with retailers.<br /><br />
                                        </span>
                                    </div>
                                </div>


                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={mayBg} alt='' className='imgabs' />
                                    <img src={sep11} alt='' className='sep11' />
                                    <img src={sep12} alt='' className='sep12' />
                                    {/* <span className='sku'>SKU</span>
                                    <span className='upc'>UPC</span> */}
                                </div>
                                <div className='postsRight postRightAuto'>
                                    <div className='monthPost'>
                                        <span>September, 2022</span>
                                        <span className='postSpecial'>SKU vs UPC/EAN </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>

                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span className='codesSpan'>
                                            <span className='colorOrange fontSize underlineMe'>SKU</span><br />
                                            * Stock keeping unit<br />
                                            * Unique for each retailer<br />
                                            * Varies in length<br />
                                            * Identifies product traits<br />
                                            * Numeric or Alphanumeric Used <br />
                                            * Generated by retailers as per their convenience<br />

                                            <span className='colorOrange fontSize underlineMe'>UPC/EAN</span><br />
                                            * Universal product code/European<br />
                                            * Consistent across retailers<br />
                                            * 12 digits (UPC)/ 13 digits (EAN)<br />
                                            * Identifies manufacturer and products<br />
                                            * Supply chain between multiple parties<br />
                                        </span>
                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}
                    {data === 'june' ?
                    <div className="postsDiv">
                        <div className='postClipped'>
                            <div className='postHeading'>
                                <span>Internet of Things (IoT)</span>
                            </div>
                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={iotbg} alt='' className='imgabs' />
                                    <img src={iotimg5} alt='' className='iotimg5' />
                                    
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Feb, 2023</span>
                                        <span className='postSpecial'>What is IoT? </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        IoT or Internet of things is a wide spectrum of instruments! It usually refers to devices which are connected to the internet and send information of the surroundings or the paramaeters of the machine they are connected with, remotely to an application/server. <br /> <br />
                                        <span className='colorWhite'>Iot devices have brought huge efficiencies into industries all over the world by reducing the need of manual interventions, on site surveillance, manual data entries, and making data available instantly and in real time. </span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='postsMainDiv'>
                                <div className='postsLeft'>
                                    <img src={iotbg} alt='' className='imgabs' />
                                    <img src={commpost1} alt='' className='comm-img' />
                                    
                                </div>
                                <div className='postsRight'>
                                    <div className='monthPost'>
                                        <span>Mar, 2023</span>
                                        <span className='postSpecial'>Wireless Connections Overview </span>
                                    </div>
                                    <div className='postTop'>
                                        <span>
                                        Wireless technologies <span className='colorWhite'>are essential (but not necessary) for the functioning of </span> 
                                        IoT devices. <span className='colorWhite'>WiFi and Cellular networks are just the beginning! There is a bigger world out there...</span>
                                        </span>
                                    </div>
                                    <div className='postBottom'>
                                        <span>

                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="posts_footer">
                            <Footer />
                        </div>
                    </div>
                    : ''}

            </div>
        </>
    )
}
export default Posts;