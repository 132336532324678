import React, { useState} from 'react';
import styles from './styles.module.css';
import { StyledHeading } from "../../HelperComponents";
import KPMGIcon1 from '../../assets/ourAwards/KPMGIcon1.svg';
import KPMGImage1 from '../../assets/ourAwards/KPMGImage1.svg';
import startupIndiaIcon2 from '../../assets/ourAwards/startupIndiaIcon2.svg';
import startupIndiaImage2 from '../../assets/ourAwards/startupIndiaImage2.svg';
import lumisIcon3 from '../../assets/ourAwards/lumisIcon3.svg';
import lumisImage3 from '../../assets/ourAwards/lumisImage3.svg';
import kpmgStartIcon4 from '../../assets/ourAwards/kpmgStartIcon4.svg';
import kpmgStartImage4 from '../../assets/ourAwards/kpmgStartImage4.svg';
import entrepreneurIcon5 from '../../assets/ourAwards/entrepreneurIcon5.svg';
import entrepreneurImage5 from '../../assets/ourAwards/entrepreneurImage5.svg';
import economicIcon6 from '../../assets/ourAwards/economicIcon6.svg';
import economicImage6 from '../../assets/ourAwards/economicImage6.svg';
import CIOIcon7 from '../../assets/ourAwards/CIOIcon7.svg';
import CIOImage7 from '../../assets/ourAwards/CIOImage7.svg';
import internetBestIcon8 from '../../assets/ourAwards/internetBestIcon8.svg';
import internetBestImage8 from '../../assets/ourAwards/internetBestImage8.svg';
import leftArr from '../../assets/ourAwards/leftArr.svg';
import rightArr from '../../assets/ourAwards/rightArr.svg';
import WhiteKPMGIcon1 from '../../assets/ourAwards/WhiteKPMGIcon1.svg'
import whitestartupIndiaIcon2 from '../../assets/ourAwards/whitestartupIndiaIcon2.svg'
import whitelumisIcon3 from '../../assets/ourAwards/whitelumisIcon3.svg'
import whitepkmgStartIcon4 from '../../assets/ourAwards/whitekpmgStartIcon4.svg'
import whiteentrepreneurIcon5 from '../../assets/ourAwards/whiteentrepreneurIcon5.svg'
import whiteeconomicIcon6 from '../../assets/ourAwards/whiteeconomicIcon6.svg'
import whiteCIOIcon7 from '../../assets/ourAwards/whiteCIOIcon7.svg'
import whiteinternetBestIcon8 from '../../assets/ourAwards/whiteinternetBestIcon8.svg'
import { SlideUpComponent } from '../../AnimationConents';

const OurAwards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const startX = useRef(0); 
  // const endX = useRef(0); 
  const allAwards = [
    {

      title: 'Lynkit Wins The 3rd Edition Of The KPMG (GTIC) Award 2023',
      icon: KPMGIcon1,
      img: KPMGImage1,
      whiteIcons:WhiteKPMGIcon1,
      readMoreContent: 'https://www.siliconindia.com/news/startups/kpmg-unveils-lynkit-solutions-as-victors-of-kpmg-private-enterprise-global-tech-innovator-in-india-nid-224543-cid-19.html'
    },
    {

      title: 'Lynkit Wins National Start-Up Award 2022',
      icon: startupIndiaIcon2,
      img: startupIndiaImage2,
      whiteIcons:whitestartupIndiaIcon2,
      readMoreContent: 'https://www.aninews.in/news/business/business/supply-chain-automation-company-lynkit-wins-national-start-up-awards-202220230118172222/'
    },
    {

      title: 'Lynkit Wins Lumis Entrepreneurial Excellence Award 2023',
      icon: lumisIcon3,
      img: lumisImage3,
      whiteIcons:whitelumisIcon3,
      readMoreContent: 'https://lumisawards-tiedelhi.org/'
    },
    {

      title: 'Lynkit Is Listed Amongst The Top Startups By KPMG Enrich Award',
      icon: kpmgStartIcon4,
      img: kpmgStartImage4,
      whiteIcons:whitepkmgStartIcon4,
      readMoreContent: ''
    },
    {

      title: 'Lynkit Wins Logistics /Fulfilment Start-up Of The Year 2022',
      icon: entrepreneurIcon5,
      img: entrepreneurImage5,
      whiteIcons:whiteentrepreneurIcon5,
      readMoreContent: 'https://www.entrepreneurindia.com/2022/award-winners.php'
    },
    {

      title: 'Lynkit Bags The ET Excellence Award (IT/Technology) 2022',
      icon: economicIcon6,
      img: economicImage6,
      whiteIcons:whiteeconomicIcon6,
      readMoreContent: 'https://lynkit.in/static/media/newspaper.aaf6b3b8.png'
    },
    {

      title: 'Lynkit Wins Top 10 CIO Blockchain Startup Award India 2022',
      icon: CIOIcon7,
      img: CIOImage7,
      whiteIcons:whiteCIOIcon7,
      readMoreContent: 'https://www.ciotechoutlook.com/news/bracing-for-blockchain-impact--nid-8838-cid-135.html'
    },
    {

      title: 'Lynkit Conferred With The ‘Best Company Award’ At The Internet 2.0 Conference Dubai, 2022',
      icon: internetBestIcon8,
      img: internetBestImage8,
      whiteIcons:whiteinternetBestIcon8,
      readMoreContent: 'https://medium.com/@Internet_2.0_Dubai/lynkit-conferred-with-the-best-companies-award-at-the-internet-2-0-conference-dubai-2022-ac4d9b866a7f'
    }
  ];

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === allAwards.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? allAwards.length - 1 : prevIndex - 1
    );
  };

  const previousIndex = (currentIndex === 0) ? allAwards.length - 1 : currentIndex - 1;
  const nextIndex = (currentIndex === allAwards.length - 1) ? 0 : currentIndex + 1;

  // const handleTouchStart = (e) => {
  //   startX.current = e.touches[0].clientX; // Record the initial touch position
  // };

  // const handleTouchMove = (e) => {
  //   endX.current = e.touches[0].clientX; // Record the movement position
  // };

  // const handleTouchEnd = () => {
  //   if (startX.current - endX.current > 50) {
  //     // Swiped left
  //     goToNextSlide();
  //   } else if (endX.current - startX.current > 50) {
  //     // Swiped right
  //     goToPreviousSlide();
  //   }
  // };


  return (
    <div className={styles.sliderContainer}>
      <div>
        <StyledHeading text="Our Awards" />
        <SlideUpComponent duration={1600}>
         <p className={styles.heading_para}>Explore The Awesome</p>
        </SlideUpComponent>
        
      </div>

      <div className={styles.carouselWrapper} 
        
      >
        {/* PREVIOUS SLIDE */}
        <button className={styles.left_btn_sm_screen} onClick={goToPreviousSlide}> <img src={leftArr} alt="" /></button>
        <div className={styles.sideSlide}>

          <div className={styles.iconImageContainer}>
            <div className={styles.iconContainer}>
              <img className={styles.awardIcon} src={allAwards[previousIndex].icon} />
            </div>
            <img className={styles.awardImage} src={allAwards[previousIndex].img} />
            <div className={styles.blackBgOnImgPrev}></div>
          </div>
         
        </div>

        {/* CURRENT SLIDE FOR LARGER SCREEN*/}
        <div className={styles.mainSlide}>
          <div className={styles.iconImageContainer}>
            <div className={styles.iconContainer}>
              <img className={styles.awardIcon} src={allAwards[currentIndex].icon} />
            </div>
            <img className={styles.awardImage} src={allAwards[currentIndex].img} alt='' />
          </div>
          <div className={styles.awardTitle}>
            <p>{allAwards[currentIndex].title}</p>
          </div>
        </div>

        {/* CURRENT SLIDE FOR SMALL SCREEN */}
        <div className={styles.smallMainSlide} 
        //  onTouchStart={handleTouchStart} 
        //  onTouchMove={handleTouchMove} 
        //  onTouchEnd={handleTouchEnd}
        >
         
          <div className={styles.smallIconImageContainer}> 
            <img className={styles.smallAwardIcon} src={allAwards[currentIndex].whiteIcons} />
            <img className={styles.smallAwardImage} src={allAwards[currentIndex].img} alt='' />
            <div className={styles.blackBgOnImg}></div>
          </div>
          {/* <div className={styles.awardTitle}>
            <p>{allAwards[currentIndex].title}</p>
          </div> */}
        </div>

        {/* NEXT SLIDE */}
        <div className={styles.nextSlide}>
          <div className={styles.iconImageContainer}>
            <div className={styles.iconContainer}>
              <img className={styles.awardIcon} src={allAwards[nextIndex].icon} />
            </div>
            <img className={styles.awardImage} src={allAwards[nextIndex].img} />
            <div className={styles.blackBgOnImgNext}></div>
          </div>
        </div>
        <button className={styles.right_btn_sm_screen} onClick={goToNextSlide}> <img src={rightArr} alt="" /></button>
      </div>
      <div className={styles.readMore}>
        <a href={allAwards[currentIndex].readMoreContent} target='_blank'>
          Read More
        </a>
      </div>
      <div className={styles.navigationButtons}>
        <button className={styles.prevButton} onClick={goToPreviousSlide}>
          <img src={leftArr} alt="" />
        </button>
        <button className={styles.nextButton} onClick={goToNextSlide}>
        <img src={rightArr} alt="" />
        </button>
      </div>

      {/* Dots Navigation for small screens */}
      <div className={styles.dotsWrapper}>
        {allAwards.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${currentIndex === index ? styles.activeDot : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default OurAwards;
